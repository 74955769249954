@use 'sass:math';

$scroll-bar-width: 17px;
$pipe-spacing: 0.7em;
$byline-gradient: linear-gradient(to right, rgba($gray-800, 0.73), rgba($gray-800, 0.01));

@mixin pipe-delimiter($color: $gray-400) {
  content: '';
  display: inline-block;
  width: 2px;
  height: 26px;
  background: $color;
  position: absolute;
  top: 3px;
  margin-left: $pipe-spacing;
  @include media-breakpoint-down(lg) {
    height: 21px;
  }
  @include media-breakpoint-down(sm) {
    height: 18px;
  }
}

.journey-page {
  .page-banner {
    position: relative;

    .byline-wrapper {
      background: $byline-gradient;
      color: $white;
      position: absolute;
      bottom: 0;
      width: 100%;
      padding-left: $gutter-sm;

      & * {
        display: inline-block;
      }

      @include media-breakpoint-up(sm) {
        padding-left: $gutter-md;
      }

      @include media-breakpoint-up(md) {
        padding-left: $gutter-lg;
      }

      @include media-breakpoint-up(lg) {
        padding-left: $gutter-xl;
      }

      @include media-breakpoint-up(xl) {
        padding-left: $gutter-xxl;
      }
    }

    .hero-delimiter {
      padding: 0 0.5em;
    }

    .field-herophotographer {
      span {
        padding-right: 0.4em;
      }
    }

    @include media-breakpoint-down(sm) {
      .hero-delimiter,
      .field-herocaption {
        display: none;
      }
    }
  }

  .article-info,
  .author-wrapper,
  .photographer-wrapper,
  .article-date {
    display: flex;
  }

  .article-info {
    flex-direction: column;
    margin-bottom: 1.5em;
    color: $gray-800;

    @include media-breakpoint-up(md) {
      flex-direction: row;
      margin-bottom: 0;
    }
  }

  .author-wrapper {
    margin-right: map-get($spacers, 4);
  }

  .author-label,
  .photographer-label {
    font-weight: $font-weight-bold;
    text-transform: uppercase;
    margin-right: map-get($spacers, 2);
  }

  /****** Styling for the Key Points section ******/
  .article-container aside {
    background: $gray-50;
    padding: $promo-feature-item-padding;
    margin-bottom: 2.5em;

    .key-points {
      margin-bottom: 0;
      padding-left: 0.4em;
      list-style: none;

      li {
        // inline span neeeded for SXA editor wrappers
        > span {
          display: inline;
        }
        &::before {
          content: '•';
          display: inline-block;
          margin-right: 0.4em;
        }
      }
    }
  }

  .article-date {
    justify-content: flex-end;
    margin: map-get($page-section-padding, sm) 0;
    position: relative;
    &::after {
      content: '';
      display: block;
      width: 90px;
      height: 3px;
      background: #c9c9c9;
      position: absolute;
      bottom: -30px;
      right: 0;
    }
    > div:first-child {
      position: relative;
      &::after {
        @include pipe-delimiter();
      }
    }

    .field-publisheddate,
    .field-revieweddate {
      margin-left: ($pipe-spacing * 2);
    }
  }

  .article-header {
    padding-top: map-get($page-section-padding, sm);

    @include media-breakpoint-up(md) {
      padding-top: map-get($page-section-padding, md);
    }

    @include media-breakpoint-up(lg) {
      padding-top: map-get($page-section-padding, lg);
    }
  }

  @mixin figure-column-layout() {
    display: flex;

    figure {
      padding: 0 8px;

      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        padding-right: 0;
      }
    }

    .figure-image-wrapper {
      width: 100%;
      position: relative;
      overflow: hidden;
    }

    // !important required to override inline values injected in Experience Editor
    img {
      width: auto !important;
      height: 100% !important;
      max-width: none;
      min-width: 100%;
      left: 50%;
      transform: translate(-50%, 0);
      position: absolute;
      bottom: 0;
      top: 0;
    }
  }

  .three-figure-column {
    @include media-breakpoint-down(lg) {
      img {
        width: 100% !important;
        height: auto !important;
      }
    }

    @include media-breakpoint-up(lg) {
      @include figure-column-layout();

      figure {
        width: 33.333%;
        box-sizing: content-box;
      }

      .figure-image-wrapper {
        height: 230px;
      }

      figcaption {
        margin-top: 0.5em;
      }
    }
    @include media-breakpoint-up(xl) {
      .figure-image-wrapper {
        height: 290px;
      }
    }
  }

  .two-figure-column {
    @include media-breakpoint-down(lg) {
      img {
        width: 100% !important;
        height: auto !important;
      }
    }
    @include media-breakpoint-up(lg) {
      @include figure-column-layout();

      figure {
        width: 50%;
        box-sizing: content-box;
      }

      .figure-image-wrapper {
        height: 370px;
      }

      figcaption {
        margin-top: 0.5em;
      }
    }
  }

  // Portrait image snippets: images must be set at a 2:3 ratio with a minimum width of 620px (x 930px)
  .two-figure-column.portrait {
    @include media-breakpoint-up(md) {
      @include figure-column-layout();

      figure {
        width: 50%;
        box-sizing: content-box;
      }

      .figure-image-wrapper {
        height: 505.5px;
      }
    }
    @include media-breakpoint-up(lg) {
      .figure-image-wrapper {
        height: 685.5px;
      }
    }
    @include media-breakpoint-up(xl) {
      .figure-image-wrapper {
        height: 820.5px;
      }
    }
    @include media-breakpoint-up(xxl) {
      .figure-image-wrapper {
        height: 925.5px;
      }
    }
  }

  .three-figure-column.portrait {
    @include media-breakpoint-up(md) {
      @include figure-column-layout();

      figure {
        width: 33.333%;
        box-sizing: content-box;
      }

      .figure-image-wrapper {
        height: 328.5px;
      }
    }
    @include media-breakpoint-up(lg) {
      .figure-image-wrapper {
        height: 448.5px;
      }
    }
    @include media-breakpoint-up(xl) {
      .figure-image-wrapper {
        height: 538.5px;
      }
    }
    @include media-breakpoint-up(xxl) {
      .figure-image-wrapper {
        height: 609px;
      }
    }
  }

  .article-listing {
    .category-wrapper,
    .editors-choice {
      position: relative;
      margin-bottom: 5px;

      & * {
        color: inherit;
      }

      .desktop-heading {
        display: none;
        @include media-breakpoint-up(lg) {
          display: inline-block;
        }
      }

      & a {
        text-decoration: none;
        @include hover-focus() {
          text-decoration: underline;

          .see-more-link {
            text-decoration: underline;
          }
        }
      }

      .see-more-link {
        display: none;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);

        @include media-breakpoint-up(lg) {
          display: block;
        }

        &::after {
          @include fa-icon;
          @extend .fal;

          content: fa-content($fa-var-chevron-circle-right);
          line-height: 1.5em;
          font-size: 85%;
          vertical-align: baseline;
          margin-left: 0.4em;
        }
      }
    }

    ul {
      list-style: none;
      padding-left: 0;
      display: flex;
      flex-wrap: wrap;
      margin-left: -5px;
      margin-right: -5px;

      @include media-breakpoint-down(sm) {
        margin-left: 0;
        margin-right: 0;
      }

      @include media-breakpoint-down(xs) {
        margin-right: -$mobile-screen-padding-h;
        margin-left: -$mobile-screen-padding-h;
      }
    }

    .article-item {
      flex: 1 0 auto;
      width: 33.333%;
      padding: 0 5px;
      position: relative;
      height: 260px;

      a {
        overflow: hidden;
        display: block;
        .article-wrapper {
          transition: transform 0.7s;
        }
        @include hover-focus() {
          text-decoration: none !important;
          .article-wrapper {
            transform: scale(1.05);
          }
        }
      }

      a,
      .article-wrapper {
        height: 100%;
      }

      .copy-wrapper * {
        color: $white;
      }

      .photo-tint {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        position: absolute;
        top: 0;
        left: 0;
        background: linear-gradient(transparent, rgba(0, 0, 0, 0.35));
        background-clip: content-box;
        padding: 0 5px;

        @include media-breakpoint-down(sm) {
          padding: 0;
        }
      }

      .copy-wrapper {
        padding: 0 2.5rem;
        width: 70%;
        @include media-breakpoint-down(md) {
          width: 100%;
        }
      }

      // Specific styles for the first full-width article-item
      &:first-child {
        width: 100%;
        margin-bottom: 10px;
        height: 450px;

        @include media-breakpoint-down(md) {
          height: 330px;
        }

        .photo-tint {
          position: absolute;
          height: 100%;
          top: 0;
          overflow: hidden;
        }

        .copy-wrapper {
          padding: 0 2.5rem;
          height: 100%;

          .type-title-wrapper {
            height: 100%;
            transition: height 0.7s;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
          }
        }

        a {
          @include hover-focus() {
            .type-title-wrapper {
              height: 40%;
            }
          }
        }
      }

      @include media-breakpoint-down(md) {
        width: 100%;
        height: 330px;
        &:first-child {
          margin-bottom: 0;
        }
      }

      @include media-breakpoint-down(sm) {
        padding: 0;
      }
    }
  }
}
