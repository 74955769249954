// Button styles copied from Bootstrap in a way such that they can be used by any components across our SXA solution

@mixin btn {
  display: inline-block;
  font-weight: $btn-font-weight;
  color: $body-color;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: $btn-border-width solid transparent;
  text-decoration: none;
  border-radius: $btn-border-radius;
  line-height: $btn-line-height;
  padding: 13px 37px;
  font-size: 19px;
  @include transition($btn-transition);

  @include hover {
    color: $body-color;
    text-decoration: none;
  }

  &:focus,
  &.focus {
    outline: 0;
    box-shadow: $btn-focus-box-shadow;
  }

  // Disabled comes first so active can properly restyle
  &.disabled,
  &:disabled {
    opacity: $btn-disabled-opacity;
    @include box-shadow(none);
  }

  // Opinionated: add "hand" cursor to non-disabled .btn elements
  &:not(:disabled):not(.disabled) {
    cursor: pointer;
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    @include box-shadow($btn-active-box-shadow);

    &:focus {
      @include box-shadow($btn-focus-box-shadow, $btn-active-box-shadow);
    }
  }

  @include media-breakpoint-up(sm) {
    font-size: 20px;
    padding: 16px 42px;
  }

  @include media-breakpoint-up(lg) {
    font-size: 22px;
    padding: 21px 50px;
  }
}

.btn {
  @include btn();
}

//
// Link buttons
//

// Make a button look and behave like a link
// Copied from Boostrap so we can extend it
@mixin btn-link() {
  font-weight: $font-weight-normal;
  color: $link-color;
  text-decoration: $link-decoration;

  @include hover {
    color: $link-hover-color;
    text-decoration: $link-hover-decoration;
  }

  &:focus,
  &.focus {
    text-decoration: $link-hover-decoration;
    box-shadow: none;
  }

  &:disabled,
  &.disabled {
    color: $btn-link-disabled-color;
    pointer-events: none;
  }
}

.btn-link,
%btn-link {
  @include btn-link();
}

//
// Alternate buttons
//

$button-colors: (
  'primary': $active-blue,
  'goal': $ract-red,
);

@each $color, $value in $button-colors {
  .btn-#{$color} {
    @include button-variant($value, $value);
  }
}

.btn-white {
  @include button-variant($white, $white, $active-blue, $white);

  color: $active-blue;
}

.btn-outline-white {
  @include button-outline-variant($white, $active-blue, $white);
}

.btn-outline-ract {
  @include button-outline-variant($active-blue, $active-blue, $white);

  color: #666;
}

//
// Button Sizes
//

@mixin btn-lg() {
  font-size: 21px;
  padding: 18px 45px;

  @include media-breakpoint-up(sm) {
    font-size: 22px;
    padding: 21px 48px;
  }

  @include media-breakpoint-up(lg) {
    font-size: 24px;
    padding: 26px 57px;
  }
}

.btn-lg,
%btn-lg {
  @include btn-lg();
}

@mixin btn-sm() {
  font-size: 15px;
  padding: 8px 30px;

  @include media-breakpoint-up(sm) {
    font-size: 16px;
    padding: 11px 34px;
  }

  @include media-breakpoint-up(lg) {
    font-size: 20px;
    padding: 16px 42px;
  }
}

.btn-sm,
%btn-sm {
  @include btn-sm();
}

@mixin btn-xs() {
  font-size: 15px;
  padding: 8px 30px;
  min-width: 150px;

  @include media-breakpoint-up(sm) {
    font-size: 16px;
    padding: 11px 20px;
  }
}

.btn-xs,
%btn-xs {
  @include btn-xs();
}

//
// Block button
//

.btn-block {
  display: block;
  width: 100%;

  // Vertically space out multiple block buttons
  + .btn-block {
    margin-top: $btn-block-spacing-y;
  }
}

// Specificity overrides
input[type='submit'],
input[type='reset'],
input[type='button'] {
  &.btn-block {
    width: 100%;
  }
}
