@use 'sass:math';

$padding-sm-vertical: 0.3rem; //map-get($spacers, 1);
$padding-sm-horizontal: 0.2rem; //map-get($spacers, 2);
$padding-md-vertical: 0.5rem; //map-get($spacers, 2);
$padding-md-horizontal: 0.4rem; //map-get($spacers, 3);

$mobile-table-breakpoint: sm;
$desktop-table-breakpoint: breakpoint-next($mobile-table-breakpoint);

.comparison-table {
  width: 100%;
  border-collapse: collapse;

  &.item-count-1 {
    max-width: 500px;
    margin: 0 auto;
  }
  &.item-count-2 {
    td {
      width: 50%;
      @include media-breakpoint-up($desktop-table-breakpoint) {
        width: 33.33333333%;
      }
    }
  }
  &.item-count-3 {
    td {
      width: 33.33333333%;
      @include media-breakpoint-up($desktop-table-breakpoint) {
        width: 25%;
      }
    }
  }
  &.item-count-4 {
    td {
      width: 25%;
      @include media-breakpoint-up($desktop-table-breakpoint) {
        width: 20%;
      }
    }
  }
  &.item-count-5 {
    td {
      width: 20%;
      @include media-breakpoint-up($desktop-table-breakpoint) {
        width: 16.666666666666667%;
      }
    }
  }

  th,
  td {
    text-align: center;
    border: 2px solid $body-bg;
    padding: $padding-sm-vertical $padding-sm-horizontal;
    @include media-breakpoint-up(sm) {
      border-left-width: 4px;
      border-right-width: 4px;
    }
    @include media-breakpoint-up(md) {
      padding: $padding-md-vertical $padding-md-horizontal;
    }

    border-top-width: 1px;
    border-bottom-width: 1px;
  }

  thead {
    th,
    td {
      border-top-width: 0;
      border-bottom-width: 0;
      background: $gray-50;

      &.is-recommended {
        background: $active-blue;
        color: color-yiq($active-blue);
      }

      &.ct-label-cell {
        background: none;
      }

      .thlink {
        color: inherit;
      }
    }

    a {
      text-decoration: none;

      &:hover,
      &:focus {
        text-decoration: none;
      }
    }
  }

  tbody {
    td {
      background: $gray-50;

      &.is-recommended {
        background: $gray-100;
      }

      font-weight: $font-weight-normal;
      line-height: 1;
      height: 50px;
      vertical-align: middle;
    }

    th {
      text-align: left;
      font-size: $font-size-sm-ish;
      line-height: 1.1;
    }

    .ct-label-row th,
    .ct-heading-row th {
      padding: ($padding-sm-vertical * 2) 0 ($padding-sm-vertical / 2);

      @include media-breakpoint-up(md) {
        padding: ($padding-md-vertical * 2) $padding-md-horizontal $padding-sm-vertical;
      }
    }
    .ct-label-cell {
      border-top-color: $gray-100;
      border-bottom-color: $gray-100;
      border-right-width: 0;
      font-size: $font-size-sm-ish;
      vertical-align: middle;

      @include media-breakpoint-up($desktop-table-breakpoint) {
        text-align: right;
      }
    }
  }
  tfoot {
    td {
      padding: 0.5rem;
      @include media-breakpoint-up(sm) {
        padding: $padding-sm-vertical;
      }

      @include media-breakpoint-up(md) {
        padding: $padding-md-vertical;
      }

      @include media-breakpoint-up(md) {
        padding: 1rem;
      }
    }
    .ct-more-link {
      display: block;
      font-size: $font-size-sm;
      text-decoration: underline;
      margin-top: 0.5em;
    }
  }

  .ct-conditions {
    display: block;
    font-size: $font-size-xs;
    font-weight: $font-weight-normal;
  }

  .ct-label-cell {
    background: none;
    &:first-child {
      border-right-width: 0;
      + td,
      + th {
        border-left-width: 0;
      }
    }
    @include media-breakpoint-down($mobile-table-breakpoint) {
      &.ct-label-cell {
        @include sr-only();
      }
    }
  }

  .ct-heading-row {
    th {
      text-align: left;

      @include media-breakpoint-down($mobile-table-breakpoint) {
        padding-bottom: 0;
        .ct-heading-text,
        .ct-heading-sub-text {
          display: block;
        }
      }
    }

    .ct-heading-text {
      font-weight: $headings-font-weight;
      font-size: $font-size-base;
      color: $headings-color;
      padding-top: $padding-sm-vertical;

      @include media-breakpoint-up(md) {
        font-size: $font-size-lg;
      }
    }

    .ct-heading-sub-text {
      font-size: $font-size-sm;
      line-height: 1.1;

      @include media-breakpoint-up(md) {
        font-size: $font-size-base;
        line-height: 1.1;
      }
    }
  }

  .ct-value {
    @include media-breakpoint-down(xs) {
      font-size: $font-size-sm;
      font-weight: $font-weight-normal;
    }
  }

  // Change to desktop layout with the labels on the left
  @include media-breakpoint-up(md) {
    .ct-label-cell .ct-conditions {
      display: block;
    }
    .ct-label-cell {
      display: table-cell;
    }
    .ct-label-row {
      &,
      &.ct-extra-detail {
        display: none;
      }
    }
  }

  abbr {
    text-decoration: none;
  }
}

// more info trigger
.ct-more-info-trigger {
  color: $gray-600;
  padding: 0;
  border: none;
  background: none;

  &:hover,
  &:focus {
    color: $link-hover-color;
    outline: none;
  }

  position: absolute;
  top: 50%;
  right: 0.5em;
  margin-top: -0.5em;
  line-height: 1;
  cursor: pointer;
}

.comparison-table tbody .ct-label-cell,
.comparison-table tbody .ct-label-row th {
  position: relative; // position relative for the absolute positioned question marks
  padding-right: 2rem;
}

.comparison-table .comparison-recommended-product-indicator {
  font-size: $font-size-sm;
  line-height: 1.1;
  td {
    background: none;
  }
  .is-recommended {
    color: color-yiq($midnight-blue);
    background: $midnight-blue;
    border-top-left-radius: math.div($leaf-radius, 4);
    border-top-right-radius: math.div($leaf-radius, 4);
  }
}

.comparison-product-titles {
  th:not(.is-recommended):first-of-type {
    border-top-left-radius: math.div($leaf-radius, 2);
  }

  th:not(.is-recommended):last-of-type {
    border-top-right-radius: math.div($leaf-radius, 2);
  }

  th,
  td {
    border-bottom-width: 1px !important;
    vertical-align: middle;

    @include media-breakpoint-down(xs) {
      // Allow the content authors to specifiy the height of the table cell on
      //  mobile after the tables have been rotated sideways
      &[data-mobile-height='3'] {
        height: 3em;
      }
      &[data-mobile-height='4'] {
        height: 4em;
      }
      &[data-mobile-height='5'] {
        height: 5em;
      }
      &[data-mobile-height='6'] {
        height: 6em;
      }
      &[data-mobile-height='7'] {
        height: 7em;
      }
      &[data-mobile-height='8'] {
        height: 8em;
      }
      &[data-mobile-height='9'] {
        height: 9em;
      }
      &[data-mobile-height='10'] {
        height: 10em;
      }
      &[data-mobile-height='12'] {
        height: 12em;
      }
      &[data-mobile-height='13'] {
        height: 13em;
      }
      &[data-mobile-height='14'] {
        height: 14em;
      }
    }

    .thlink {
      display: block;
      width: 1px;
      margin: 0 auto;

      @include media-breakpoint-up(md) {
        width: unset;
        margin: unset;
      }
    }
  }
}

.comparison-product-title {
  transform: translateX(-50%) rotate(270deg);
  transform-origin: center;
  line-height: 0.9;
  display: inline-block;
  font-size: 18px;

  @include media-breakpoint-up(md) {
    transform: none;
    transform-origin: unset;
    line-height: unset;
  }
}

.comparison-product-prices {
  line-height: 1;

  .is-recommended {
    .comparison-product-price,
    .comparison-product-price-units {
      color: $white;
    }
  }

  @include media-breakpoint-up(lg) {
    line-height: 0.85;
  }
}

.comparison-product-price {
  small {
    font-weight: inherit;

    @include media-breakpoint-up(md) {
      font-size: 60%;
    }
  }
}

.comparison-product-price-units {
  font-size: $font-size-sm;
  @include media-breakpoint-up(lg) {
    font-size: $font-size-sm-ish;
  }
}

.comparison-product-descriptions {
  display: none;

  th,
  td {
    padding-top: 0;
  }

  .is-recommended {
    .comparison-product-description {
      color: $white;
    }
  }

  @include media-breakpoint-up(sm) {
    display: table-row;
  }
}

.comparison-product-description {
  display: block;
  padding-bottom: $padding-md-vertical;
}

.comparison-table-teaser {
  .ct-label-cell {
    border: none;
  }

  .filler-cell {
    background: linear-gradient(180deg, $gray-50, rgba(255, 255, 255, 0));
    border: 2px solid $body-bg;
  }
}

.comparison-table-show-extra {
  text-align: center;

  .comparison-table & td {
    background: none;
    border: none;
  }
}

.comparison-table-toggle-all-features {
  padding: $padding-sm-vertical $padding-sm-horizontal;
  text-decoration: none;
}

.comparison-table-terms {
  font-size: $font-size-sm;

  :last-child {
    margin-bottom: 0;
  }
}
