$fixed-tag-width: 120px;
$fixed-tag-height: 28px;

.tag {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  text-transform: uppercase;
  padding: 2px 8px;
  font-size: 14px;
  font-weight: $font-weight-medium;
  align-self: start;
  border-style: solid;
  border-width: 2px;
  text-align: center;
  height: $fixed-tag-height;
  line-height: 18px;

  &.hundred-year-theme {
    color: $hundred-year-gold;
    border-color: $hundred-year-gold;
  }
}
