.state-election-table {
  max-width: 980px;
  width: 100%;
  margin: 0 auto 100px;

  td,
  th {
    padding: 15px;
    border-left: 1px solid #fff;
    vertical-align: middle !important;
    font-size: 20px;
  }

  th {
    font-weight: $font-weight-normal;
  }

  .title td {
    background: #1c3559;
    color: #fff;
    text-align: center;
    padding: 50px 30px;
    font-size: 40px;
    line-height: 48px;
  }

  .title.grey td {
    background: #666;
  }

  .policy,
  .policy-mobile {
    border-bottom: 10px solid #f5f5f5;
    color: #666;
  }

  .labor {
    border-bottom: 10px solid #cb2b3610;
    color: #cb2b36;
    text-align: center;
  }

  .liberal {
    border-bottom: 10px solid #006cca10;
    color: #006cca;
    text-align: center;
  }

  .greens {
    border-bottom: 10px solid #008c4410;
    color: #008c44;
    text-align: center;
  }

  th.policy {
    background: #eee;
    border-bottom: none;
    line-height: 20px;
  }

  th.labor {
    background: #cb2b3610;
    border-bottom: none;
    line-height: 20px;
  }

  th.liberal {
    background: #006cca10;
    border-bottom: none;
    line-height: 20px;
  }

  th.greens {
    background: #008c4410;
    border-bottom: none;
    line-height: 20px;
  }

  .fad {
    font-size: 40px;
  }

  .fa-check-circle {
    color: #23d832;
  }

  .fa-minus-circle {
    color: #d8bd23;
  }

  .fa-times-circle {
    color: #d83e23;
  }

  .policy-mobile {
    display: none;
  }

  tr div {
    display: inline-block;
    font: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background: #f5f5f5;
    padding: 15px 20px 12px;
    margin-top: 16px;

    span {
      font-weight: $font-weight-bold;
    }
  }

  @include media-breakpoint-down(md) {
    .policy-mobile {
      display: table-row;
      border: 0;
    }
    .policy {
      border: 0;
      clip: rect(0, 0, 0, 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }

    td,
    th {
      font-size: 16px;
    }
  }
}

.fcc {
  color: #23d832;
}

.fmc {
  color: #d8bd23;
}

.ftc {
  color: #d83e23;
}

.fbc {
  color: #666;
}

.state-election-table.legend {
  border: 10px solid #f5f5f5;
  border-collapse: collapse;
  text-align: center;

  td {
    width: 25%;
  }

  i {
    padding-bottom: 10px;
  }

  .fbc span {
    display: block;
    height: 40px;
    line-height: 40px;
    font-size: 26px;
    font-weight: $font-weight-bold;
    padding-bottom: 10px;
    color: #ccc;
  }

  @include media-breakpoint-down(md) {
    td {
      display: block;
      width: 100%;
    }
  }
}
