.video-block {
  .component-content {
    .video-container {
      overflow: hidden;
      position: relative;
      padding-bottom: 56.25%; // 16:9 ratio
      height: 0;

      iframe {
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        position: absolute;
        border: none;
      }
    }
  }
}
