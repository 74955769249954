.social-media-share {
  background: '$social-media-share-bg';
  text-align: right;
  ul {
    list-style-type: none;
    li {
      display: inline-block;
      margin: map-get($spacers, 1);
    }
    li a {
      display: block;
    }
  }
  .icon {
    font-size: 2em;
  }
}
