// First import our functions as the rem() function is being used in some vars
@import 'abstracts/functions';

// Shared variables
@import 'abstracts/vars';

// Needs to be imported before other so that these don't override other scss classes
@import 'bootstrap/scss/bootstrap-reboot';
@import 'bootstrap/scss/bootstrap-grid';

// Mixins and vars - including BS, custo, and several from SXA theme export
@import 'abstracts/mixins';

// Import Font Awesome
@import '@fortawesome/fontawesome-pro/scss/fontawesome';
@import '@fortawesome/fontawesome-pro/scss/light';

// Manually import Font Awesome SVG styles to improve SSR
@import '@fortawesome/fontawesome-svg-core/styles.css';

@import 'node_modules/react-modal-video/scss/modal-video.scss';

// Import select Bootstrap styles for use across the entire site
@import 'bootstrap/scss/tables';
@import 'bootstrap/scss/utilities';
@import 'bootstrap/scss/popover'; // used for tooltips such as in the comparison table
@import 'bootstrap/scss/forms';
@import 'bootstrap/scss/spinners';

// Global
@import 'core/header';
@import 'core/footer';
@import 'core/page-layout';
@import 'core/type';
@import 'core/comparison-table';
@import 'core/roadside-feature-table';
@import 'core/error-page';

// Common
@import 'styles/common/bg-alt';
@import 'styles/common/width';
@import 'base/photo-listing/photo-list';
@import 'base/photo-listing/photo-listing';
@import 'base/photo-listing/photo-grid';

// Pages
@import 'core/article-page';
@import 'core/article-listing-page';
@import 'core/product-page';
@import 'core/journeys-article-page';
@import 'core/journeys-landing';
@import 'core/journeys-article-listing';
@import 'core/search-page';
@import 'core/form';

// Components
@import 'component-link-list';
@import 'component-accordion';
@import 'component-banner';
@import 'component-breadcrumb';
@import 'component-cta-grid';
@import 'component-feature-item';
@import 'component-feature-contact-modal';
@import 'component-promotion-tile';
@import 'component-image-link-list';
@import 'component-image-block';
@import 'component-notice';
@import 'component-top-tasks';
@import 'component-icon-link-list';
@import 'component-snippet';
@import 'component-page-list';
@import 'component-loan-calculator';
@import 'component-video-block';
@import 'component-journey-collection';
@import 'component-search-hits';
@import 'component-download-for-mobile';
@import 'component-roadside-group-cover-comparison-table';
@import 'component-state-election-table';
@import 'component-forms';
@import 'component-bubble-icons';
@import 'component-tag';
@import 'component-pagination';
@import 'component-rich-text';
@import 'component-social-media-share';
@import 'component-success-toast';
@import 'component-key-road-funding-priorities';
@import 'component-html-block';
