$cta-grid-padding-v: 60px;

.cta-grid {
  .component-content {
    ul {
      display: flex;
      flex-wrap: wrap;
      padding-left: 0;
      margin-bottom: -$cta-grid-padding-v;
      list-style-type: none;

      li {
        display: flex;
        padding-bottom: $cta-grid-padding-v;
        width: 100%;

        & .item-wrapper {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin: 0 auto;
          width: 100%;
          text-align: center;

          &.hundred-year-theme {
            background: $hundred-year-midnight-blue;
          }

          .cta-grid-icon {
            font-size: 3rem;
            color: $ract-blue;
          }

          .cta-grid-icon.hundred-year-theme {
            color: $hundred-year-midnight-blue;
          }

          .cta-grid-text {
            margin: map-get($spacers, 4) 0;
            color: $text-basic;
            width: 65%;
          }

          .btn {
            margin-top: auto;
            &.hundred-year-theme {
              color: $white;
              &.solid {
                background-color: $hundred-year-gold;
                border-color: $hundred-year-gold;
                &:hover {
                  background-color: $hundred-year-gold-hover;
                  border-color: $hundred-year-gold-hover;
                }
              }
              &.outline {
                background-color: $hundred-year-midnight-blue;
                border-color: $hundred-year-gold;

                &:hover {
                  background: $hundred-year-gold;
                  border-color: $hundred-year-gold;
                }
              }
            }
          }
        }

        @include media-breakpoint-up(sm) {
          width: 50%;
        }

        @include media-breakpoint-up(md) {
          width: 33.3333%;
        }
      }
    }
  }
}

@import 'variants/cta-grid/default';
@import 'variants/cta-grid/with-background-colors';
