@use 'sass:math';

$padding-sm-vertical: 0.5rem; //map-get($spacers, 1);
$padding-sm-horizontal: 0.4rem; //map-get($spacers, 2);
$padding-md-vertical: 0.7rem; //map-get($spacers, 2);
$padding-md-horizontal: 0.9rem; //map-get($spacers, 3);

$mobile-table-breakpoint: sm;
$desktop-table-breakpoint: breakpoint-next($mobile-table-breakpoint);

.roadside-feature-table {
  width: 100%;
  border-collapse: collapse;

  td {
    @include media-breakpoint-up($desktop-table-breakpoint) {
      width: 50%;
    }
  }

  th,
  td {
    text-align: center;
    padding: $padding-sm-vertical $padding-sm-horizontal;
    border-top-width: 1px;
    border-bottom-width: 1px;
    border-left-width: 0;
    border-right-width: 0;

    @include media-breakpoint-up(md) {
      border: 2px solid $body-bg;
      padding: $padding-md-vertical $padding-md-horizontal;
    }
  }

  .roadside-product-titles {
    border: 2px solid $body-bg;
    border-top-width: 1px;
    border-bottom-width: 1px;
    border-left-width: 0;
    border-right-width: 0;
  }

  thead {
    th,
    td {
      border-top-width: 0;
      background: $active-blue;
      color: color-yiq($active-blue);

      .thlink {
        color: inherit;
      }
    }

    a {
      text-decoration: none;

      &:hover,
      &:focus {
        text-decoration: none;
      }
    }
  }

  tbody {
    td {
      background: $gray-50;
      font-weight: $font-weight-normal;
      line-height: 1;
      height: 50px;
      vertical-align: middle;
    }

    th {
      text-align: left;
      font-size: $font-size-sm-ish;
      line-height: 1.1;
    }

    .rft-label-row th {
      padding: ($padding-sm-vertical * 2) 0 ($padding-sm-vertical / 2);

      @include media-breakpoint-up(md) {
        padding: ($padding-md-vertical * 2) $padding-md-horizontal $padding-sm-vertical;
      }
    }
    .rft-label-cell {
      font-size: $font-size-sm-ish;
      vertical-align: middle;
      padding-left: 1em;
    }
  }
  tfoot {
    td {
      padding: 0.5rem;
      @include media-breakpoint-up(sm) {
        padding: $padding-sm-vertical;
      }

      @include media-breakpoint-up(md) {
        padding: $padding-md-vertical;
      }

      @include media-breakpoint-up(md) {
        padding: 1rem;
      }
    }
  }

  .rft-conditions {
    display: block;
    font-size: $font-size-xs;
    font-weight: $font-weight-normal;
  }

  .rft-label-cell {
    background: $gray-50;
    &:first-child {
      border-right-width: 4px;
      + td,
      + th {
        border-left-width: 0;
      }
    }
    @include media-breakpoint-down($mobile-table-breakpoint) {
      &.rft-label-cell {
        @include sr-only();
      }
    }
  }

  // Change to desktop layout with the labels on the left
  @include media-breakpoint-up(md) {
    .rft-label-cell .rft-conditions {
      display: block;
    }
    .rft-label-cell {
      display: table-cell;
    }
    .rft-label-row {
      display: none;
    }
  }

  abbr {
    text-decoration: none;
  }
}

// more info trigger
.rft-more-info-trigger {
  color: $gray-600;
  padding: 0;
  border: none;
  background: none;

  &:hover,
  &:focus {
    color: $link-hover-color;
    outline: none;
  }

  position: absolute;
  top: 50%;
  right: 0.5em;
  margin-top: -0.5em;
  line-height: 1;
  cursor: pointer;
}

.roadside-feature-table tbody .rft-label-cell,
.roadside-feature-table tbody .rft-label-row th {
  position: relative; // position relative for the absolute positioned question marks
  padding-right: 2rem;
}

.roadside-product-titles {
  th {
    border-top-left-radius: math.div($leaf-radius, 2);
    border-top-right-radius: math.div($leaf-radius, 2);
  }

  th,
  td {
    border-bottom-width: 1px !important;
    vertical-align: middle;
    height: 8em;

    .thlink {
      display: block;
    }
  }
}

.roadside-product-title {
  display: inline-block;
  font-size: 40px;
}

.roadside-product-prices {
  line-height: 1;

  .roadside-product-price,
  .roadside-product-price-units {
    color: $white;
  }

  @include media-breakpoint-up(lg) {
    line-height: 0.85;
  }
}

.roadside-product-price {
  font-size: 40px;
}

.roadside-product-price-units {
  font-size: $font-size-sm;
  @include media-breakpoint-up(lg) {
    font-size: $font-size-sm-ish;
  }
}

.roadside-product-descriptions {
  display: none;

  th,
  td {
    padding-top: 0;
  }

  .is-recommended {
    .roadside-product-description {
      color: $white;
    }
  }

  @include media-breakpoint-up(sm) {
    display: table-row;
  }
}

.roadside-product-description {
  display: block;
  padding-bottom: $padding-md-vertical;
}

.roadside-feature-table-teaser {
  .rft-label-cell {
    border: none;
  }

  .filler-cell {
    border: 2px solid $body-bg;
    background: linear-gradient(180deg, $gray-50, rgba(255, 255, 255, 0));
    display: none;
    border-right-width: 4px;
    &:last-of-type {
      border-right-width: 0;
    }

    @include media-breakpoint-up(md) {
      display: table-cell;
    }
  }

  .filler-row {
    border: none !important;
    background: linear-gradient(180deg, $gray-50, rgba(255, 255, 255, 0));
    @include media-breakpoint-up(md) {
      display: none;
    }
  }
}

.roadside-feature-table-show-extra {
  text-align: center;

  .roadside-feature-table & td {
    background: none;
    border: none;
  }
}

.roadside-feature-table-toggle-all-features {
  padding: $padding-sm-vertical $padding-sm-horizontal;
  text-decoration: none;
}

.roadside-feature-table-terms {
  font-size: $font-size-sm;

  :last-child {
    margin-bottom: 0;
  }
}
