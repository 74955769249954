.breadcrumb {
  background: $breadcrumb-bg;
}

.breadcrumb-nav {
  padding-top: map-get($spacers, 3);
  padding-bottom: map-get($spacers, 3);

  @include media-breakpoint-up(md) {
    padding-left: map-get($spacers, 6);
    padding-right: map-get($spacers, 6);
  }
}

.breadcrumb-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.breadcrumb-item {
  display: inline-block;

  &:not(:nth-last-child(2)) {
    @include media-breakpoint-down(md) {
      display: none;
    }
  }
}

.breadcrumb-item-link {
  color: inherit;
  text-decoration: none;

  &:hover,
  &:focus {
    border-bottom: 1px solid $breadcrumb-color-active;
    text-decoration: none;
    color: $breadcrumb-color-active;
  }
}
