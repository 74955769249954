/* stylelint-disable */

$icon-item-list-padding-xs-x: 20px;
$icon-item-list-padding-sm-x: 30px;
$icon-item-list-padding-md-x: 40px;
$icon-item-list-padding-lg-x: 60px;
$icon-item-list-padding-lg-y: 30px;

// A mixin is used for the list variant so that we can reuse these styles when converting the grid variant to a list style on mobile
@mixin icon-list-styles() {
  .component-content {
    ul {
      margin-right: -$icon-item-list-padding-lg-x;
      margin-bottom: -$icon-item-list-padding-lg-y;
    }
    li {
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: auto;
      width: 50%;
      padding-right: $icon-item-list-padding-lg-x;
      padding-bottom: $icon-item-list-padding-lg-y;

      .item-wrapper {
        display: flex;

        .icon-wrapper {
          flex: 0 0 auto;
          padding-right: map-get($spacers, 2);
        }

        .text-wrapper {
          flex-grow: 1;
          flex-shrink: 1;
        }
      }
    }

    // colapse to single column
    @include media-breakpoint-down(xs) {
      ul {
        margin-right: 0;
      }
      li {
        width: 100%;
        padding: 0 0 $icon-item-list-padding-xs-x 0;

        .field-description {
          padding: 0;
        }
      }
    }

    @include media-breakpoint-only(sm) {
      .field-title {
        margin-bottom: 0;
      }
      ul {
        margin-right: -$icon-item-list-padding-sm-x;
      }
      li {
        padding-right: $icon-item-list-padding-sm-x;
      }
    }

    @include media-breakpoint-only(md) {
      ul {
        margin-right: -$icon-item-list-padding-md-x;
      }
      li {
        padding-right: $icon-item-list-padding-md-x;
      }
    }
  }
}

// Here we actually apply the styles to the list
.icon-link-list.component.list {
  @include icon-list-styles();
}

// List styles for when used inside a snippet
.icon-link-list.component.list {
  .snippet & {
    .component-content {
      ul {
        margin-right: 0;
      }
      li {
        width: 100%;
        padding: 0 0 $icon-item-list-padding-xs-x 0;

        .field-description {
          padding: 0;
        }
      }

      @include media-breakpoint-down(sm) {
        ul {
          margin-bottom: 0;
        }
      }
    }
  }
}
