/* IE bug with min-height:
    Vertical alignment of elements within a container with no explcit height does not work in IE11.
    The solution is to set a height smaller than that of the min-height value and the container will
    still get its height from the min-height.

    https://github.com/philipwalton/flexbugs/issues/231
*/

$journeys-banner-min-height-md: 460px;
$journeys-banner-min-height-lg: 850px;

.journeys-landing,
.journeys-article-listing {
  .page-banner {
    min-height: $banner-tall-min-height-sm;

    @include media-breakpoint-up(md) {
      min-height: $journeys-banner-min-height-md;
    }

    @include media-breakpoint-up(xxl) {
      min-height: $journeys-banner-min-height-lg;
    }
  }

  .journeys-landing-title {
    border-top: 1px solid $gray-300;
    padding-top: map-get($spacers, 4);
    padding-bottom: map-get($spacers, 3);
    text-align: center;
    color: $gray-800;
    position: relative;

    svg {
      max-width: 230px;

      @include media-breakpoint-up(md) {
        max-width: 350px;
        filter: drop-shadow(0 3px 6px rgba(0, 0, 0, 0.3));
      }

      @include media-breakpoint-up(xxl) {
        max-width: 100%;
      }
    }

    @include media-breakpoint-up(md) {
      padding-top: map-get($spacers, 5);
      padding-bottom: 0;
      border-top: none;
      color: $white;
    }

    @include media-breakpoint-up(lg) {
      padding-top: $spacer * 5;
    }

    @include media-breakpoint-up(xxl) {
      padding-top: $spacer * 6;
    }
  }

  .header-wrapper {
    @include make-container();

    background: $active-blue;
    margin: $middle-margin 0;
    padding: map-get($page-section-padding, sm);
    border-top-left-radius: $leaf-radius-sm;
    border-bottom-right-radius: $leaf-radius-sm;

    @include media-breakpoint-up(md) {
      border-top-left-radius: $leaf-radius-md;
      border-bottom-right-radius: $leaf-radius-md;
      margin: -120px $gutter-md 0 $gutter-md;
      width: calc(100% - #{$gutter-md});
    }

    @include media-breakpoint-up(lg) {
      margin-left: $gutter-lg;
      margin-right: $gutter-lg;
      width: calc(100% - #{$gutter-lg});
    }

    @include media-breakpoint-up(xl) {
      margin-left: $gutter-xl;
      margin-right: $gutter-xl;
      width: calc(100% - #{$gutter-xl});
    }

    @include media-breakpoint-up(xxl) {
      margin-left: $gutter-xxl;
      margin-right: $gutter-xxl;
      width: calc(100% - #{$gutter-xxl});
    }

    .inner-wrapper {
      @include media-breakpoint-up(md) {
        max-width: map-get($container-max-widths, md);
        margin: 0 auto;
        display: flex;
        flex: 1 0 auto;
        flex-wrap: wrap;
      }

      @include media-breakpoint-up(lg) {
        max-width: map-get($container-max-widths, lg);
      }

      @include media-breakpoint-up(xl) {
        max-width: 100%;
      }

      .field-featurearticletitle,
      .field-articletypename {
        color: $white;

        @include media-breakpoint-up(md) {
          max-width: map-get($container-max-widths, md);
          margin-bottom: $middle-margin;
          width: 100%;
        }

        @include media-breakpoint-up(lg) {
          max-width: map-get($container-max-widths, lg);
        }
      }

      .field-featurearticleteaser,
      .field-subheading {
        margin-bottom: $middle-margin;
        color: $light-blue;

        @include media-breakpoint-up(md) {
          width: 70%;
        }
      }
    }

    &.hundred-year-theme {
      background: $hundred-year-midnight-blue;

      a {
        background-color: $hundred-year-gold;
        border-color: $hundred-year-gold;
        color: $white;
        &:hover {
          background-color: $hundred-year-gold-hover;
          border-color: $hundred-year-gold-hover;
        }
      }
    }
  }

  .banner-wrapper {
    height: ($banner-tall-min-height-sm - 1px); // *See notes at top of file
    @include make-container();

    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: inherit;
    padding: 0;

    @include media-breakpoint-up(md) {
      max-width: none;
    }

    @include media-breakpoint-up(xl) {
      padding: 0;
      max-width: 1110px;
    }

    @include media-breakpoint-up(xxl) {
      max-width: 1250px;
    }
  }
}
