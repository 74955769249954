.success-toast--container {
  display: flex;
  align-items: center;
  color: #fff;

  .success-toast--check {
    height: 20px;
    width: 20px;
    background-color: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    // Dont display checkmark on small devices because of limited spacing
    @include media-breakpoint-down(md) {
      display: none;
    }

    .success-toast--check-icon {
      color: $gray-700;
      height: 12px;
    }
  }

  .success-toast--right-container {
    display: flex;
    align-items: center;

    .success-toast--seperator {
      height: 16px;
      width: 1px;
      background-color: rgba(255, 255, 255, 0.1);
      margin-right: 1.25rem;

      // Dont display seperator on small devices because of spacing
      @include media-breakpoint-down(md) {
        display: none;
      }
    }

    span {
      font-size: 16px;
      padding: 0 1.25rem;

      // Dont display checkmark on small devices because of spacing
      @include media-breakpoint-down(md) {
        padding: 0 0.5rem;
      }
    }

    .success-toast--close {
      all: unset;
      height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;

      .success-toast--close-icon {
        height: 100%;
      }

      :hover,
      :focus {
        cursor: pointer;
        opacity: 0.9;
      }
    }
  }
}
