%photo-listing {
  .component-content {
    padding-left: 0;
    padding-right: 0;
  }
  .component-content > ul,
  .items {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding-left: 0;
    list-style-type: none;
    margin: (0 - $photo-item-padding-v) (0 - $photo-item-padding-h);
  }

  .component-content > ul > li,
  .item {
    background: $page-list-item-bg;
    padding: $photo-item-padding-v $photo-item-padding-h;
    display: flex;
    flex-grow: 0;
    flex-shrink: 1;

    // NOTE: extra .item is just to increase specificity
    &.item a,
    > a {
      flex-grow: 1;
      flex-shrink: 1;
      display: flex;
      text-decoration: none;
      color: inherit;
      font-weight: $font-weight-bold;

      .item-wrapper,
      .article-wrapper {
        flex-grow: 1;
        flex-shrink: 1;
      }

      &:hover,
      &:focus {
        color: $active-blue;

        .image {
          transform: scale(1.05);
          opacity: 1;
        }
      }
    }

    .image {
      transform-origin: center center;
      transition: transform 0.3s ease;
      transform: scale(1);
    }
  }

  .item-wrapper,
  .article-wrapper {
    display: flex;
  }

  .text-wrapper,
  .article-container {
    flex-grow: 1;
    flex-shrink: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .image-wrapper,
  .article-image {
    display: flex;
  }

  .field-linkimage,
  .field-heroimage,
  .field-teaserimage {
    flex-grow: 1;
    flex-shrink: 1;
    overflow: hidden;
    position: relative;
  }

  //shrink the tag styles
  @include media-breakpoint-down(sm) {
    li {
      .field-teasertitle {
        line-height: 1.2;
      }
    }
  }

  // Desktop styles
  @include media-breakpoint-up(md) {
    .component-content > ul,
    .items {
      flex-direction: row;
      //margin-right: (-$middle-margin + $extrasmall-margin);
    }
  }

  &.bg-alt {
    .component-content > ul,
    .items {
      margin: (0 - $photo-item-padding-card-v) (0 - $photo-item-padding-card-h);
    }
    .component-content > ul > li,
    .item {
      border: 0;
      padding: $photo-item-padding-card-v $photo-item-padding-card-h;

      &.item a,
      > a {
        background: $white;
        transition: all 200ms ease-in-out;

        &:hover,
        &:focus {
          transform: scale(1.01);

          .field-teasertitle,
          .field-teasertext {
            color: $body-color;
          }
        }
      }
    }

    .text-wrapper,
    .article-container {
      padding: ($small-margin + $extrasmall-margin);
    }
  }
}
