.link-list {
  background: $link-list-bg;

  > .component-content {
    ul {
      background: $link-list-items-bg;
    }
    li {
      background: $link-list-item-bg;
      display: block;

      a {
        display: inline;
        color: $link-list-item-color;
        position: relative;
        padding-left: 10px;
        text-decoration: none;
        &:hover,
        &:focus {
          color: $link-list-item-color-active;
          text-decoration: none;
          border-bottom: 1px solid $link-list-item-border-active;
        }
      }
    }
  }
}
@import 'styles/link-list/list-vertical';
