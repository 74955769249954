@use 'sass:math';

.no-bkg {
  background: $white !important;
  border: none !important;
}

.roadside-group-cover-wrapper {
  .component-content {
    overflow-x: scroll;
  }
}

table.roadside-group-cover-table {
  width: 100%;
  color: $gray-500;
  margin-bottom: 30px;
  border-collapse: collapse;

  th,
  td {
    padding: 6px 10px;
    vertical-align: middle;
    border: 1px solid $white;
    text-align: center;
  }

  th {
    background: $active-blue;
    color: $white;
  }

  tr.product-titles {
    .product-title {
      background: $midnight-blue;
      color: $white;
      border-top-left-radius: math.div($leaf-radius, 2);

      .fa-check {
        font-size: 38px;

        @include media-breakpoint-up(md) {
          font-size: 50px;
        }
      }

      .h5 {
        display: block;
        color: $white;
      }
    }

    .product-title:last-of-type {
      border-top-left-radius: 0;
      border-top-right-radius: math.div($leaf-radius, 2);
    }
  }

  td {
    background: $gray-50;
    padding: 10px 10px 6px;
    height: 60px;

    span {
      color: #767c88;
    }
  }

  th.title {
    text-align: right;
    background: none;
    border: 1px solid $gray-100;
    border-left: none;
    border-right: none;
    color: $gray-500;
  }
}
