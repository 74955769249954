.component.banner {
  color: $white;
  h1,
  h2,
  h3 {
    color: inherit;
  }

  .photo-tint {
    display: flex; // IE11 flexbox min-height bug - https://stackoverflow.com/questions/44700068/flexbox-min-height-and-ie11
  }

  .banner-stack-top {
    position: relative !important;
    z-index: 9;
  }

  .text-wrapper {
    @include make-container();
    @include make-container-max-widths();

    min-height: 400px; // IE11 flexbox min-height bug
    flex-grow: 1;

    // Use flexbox to move the content around
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    // Add top and bottom padding that matches that of the rest of our page components
    padding-top: map-get($page-section-padding, md);
    padding-bottom: map-get($page-section-padding, md);
    padding-left: 25px;
    padding-right: 25px;

    @include media-breakpoint-up(md) {
      padding-top: map-get($page-section-padding, md);
      padding-bottom: map-get($page-section-padding, md);
      padding-left: 25px;
      padding-right: 25px;
    }

    @include media-breakpoint-up(lg) {
      padding-top: map-get($page-section-padding, lg);
      padding-bottom: map-get($page-section-padding, lg);
      max-width: 764px;
    }

    @include media-breakpoint-up(xl) {
      max-width: 864px;
    }

    @include media-breakpoint-up(xxl) {
      max-width: none;
      padding-left: $leaf-radius;
      padding-right: $leaf-radius;

      > .banner-content-container {
        max-width: 55%;
      }
    }
  }

  .banner-container {
    max-width: 100%; // needs to be set explicity for text to wrap on IE11
    overflow: hidden;
    width: 100%;
    height: 100%;
    position: relative;
  }

  .component-content {
    overflow: hidden;
  }

  &.position-left {
    margin-left: -$gutter;

    .component-content {
      border-bottom-right-radius: $leaf-radius-sm;
      overflow: hidden;
      width: calc(100% + #{$gutter});

      @include media-breakpoint-up(md) {
        border-bottom-right-radius: $leaf-radius-md;
      }
    }

    @include media-breakpoint-up(lg) {
      margin-left: -$gutter-lg;
      .component-content {
        width: calc(100% + #{$gutter-lg});
      }
    }

    @include media-breakpoint-up(xxl) {
      margin-left: 0;
      .component-content {
        width: 100%;
      }
    }
  }

  &.position-right {
    margin-right: -$gutter;

    .component-content {
      border-bottom-left-radius: $leaf-radius-sm;
      overflow: hidden;
      width: calc(100% + #{$gutter});

      @include media-breakpoint-up(md) {
        border-bottom-left-radius: $leaf-radius-md;
      }
    }

    @include media-breakpoint-up(lg) {
      margin-right: -$gutter-lg;
      .component-content {
        width: calc(100% + #{$gutter-lg});
      }
    }

    @include media-breakpoint-up(xxl) {
      margin-right: 0;
      .component-content {
        width: 100%;
      }
    }
  }

  &.position-center {
    margin-left: 0;
    .component-content {
      border-bottom-right-radius: $leaf-radius-sm;
      border-top-left-radius: $leaf-radius-sm;

      @include media-breakpoint-up(md) {
        border-bottom-right-radius: $leaf-radius-md;
        border-top-left-radius: $leaf-radius-md;
      }
    }
  }

  // Style the main title
  .field-bannertitle {
    text-shadow: 0 0 100px rgba(red($midnight-blue), green($midnight-blue), blue($midnight-blue), 0.2),
      0 0 12px rgba(red($midnight-blue), green($midnight-blue), blue($midnight-blue), 0.4),
      0 1px 2px rgba(red($midnight-blue), green($midnight-blue), blue($midnight-blue), 0.3);
  }

  .links-container {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;

    &.position-left {
      justify-content: flex-start;
    }

    &.position-right {
      justify-content: flex-end;
    }
  }
}

@import 'variants/banner/default';
@import 'variants/banner/hero';
@import 'variants/banner/hero-with-video';
@import 'variants/banner/promotional';

//@import "styles/banner/...";

// Focal point styles
// NOTE: 2 second level selectors allow modifier to be applied at either component top level or inside component content
.photo-bg {
  position: relative;
  background-color: $midnight-blue;
  min-height: 300px;
  z-index: 0;
}

// Photo tint styles
// NOTE: 2 second level selectors allow modifier to be applied at either component top level or inside component content
.photo-tint {
  .photo-tint-05 &,
  &.photo-tint-05 {
    background: rgba(0, 0, 0, 0.05);
  }
  .photo-tint-10 &,
  &.photo-tint-10 {
    background: rgba(0, 0, 0, 0.1);
  }
  .photo-tint-15 &,
  &.photo-tint-15 {
    background: rgba(0, 0, 0, 0.15);
  }
  .photo-tint-20 &,
  &.photo-tint-20 {
    background: rgba(0, 0, 0, 0.2);
  }
  .photo-tint-25 &,
  &.photo-tint-25 {
    background: rgba(0, 0, 0, 0.25);
  }
  .photo-tint-30 &,
  &.photo-tint-30 {
    background: rgba(0, 0, 0, 0.3);
  }
  .photo-tint-35 &,
  &.photo-tint-35 {
    background: rgba(0, 0, 0, 0.35);
  }
  .photo-tint-40 &,
  &.photo-tint-40 {
    background: rgba(0, 0, 0, 0.4);
  }
}

// Content placement styles
// NOTE: 2 second level selectors allow modifier class (e.g. .content-top-center) to be applied at
//       either component top level or inside component content
.banner {
  &.content-top-center,
  .content-top-center {
    .banner-content-container {
      text-align: center;
    }
    .text-wrapper {
      align-items: center;
    }
  }
  &.content-top-right,
  .content-top-right {
    .banner-content-container {
      text-align: right;
    }
    .text-wrapper {
      align-items: flex-end;
    }
  }
  &.content-middle-left,
  &.content-middle-center,
  &.content-middle-right,
  .content-middle-left,
  .content-middle-center,
  .content-middle-right {
    .text-wrapper {
      justify-content: space-around;
    }
  }
  &.content-middle-center,
  .content-middle-center {
    .banner-content-container {
      text-align: center;
    }
    .text-wrapper {
      align-items: center;
    }
  }
  &.content-middle-right,
  .content-middle-right {
    .banner-content-container {
      text-align: right;
    }
    .text-wrapper {
      align-items: flex-end;
    }
  }
  &.content-bottom-left,
  &.content-bottom-center,
  &.content-bottom-right,
  .content-bottom-left,
  .content-bottom-center,
  .content-bottom-right {
    .text-wrapper {
      justify-content: flex-end;
    }
  }
  &.content-bottom-center,
  .content-bottom-center {
    .banner-content-container {
      text-align: center;
    }
    .text-wrapper {
      align-items: center;
    }
  }
  &.content-bottom-right,
  .content-bottom-right {
    .banner-content-container {
      text-align: right;
    }
    .text-wrapper {
      align-items: flex-end;
    }
  }
}
