$extrasmall-margin: 5px;
$small-margin: 10px;
$middle-margin: 20px;
$large-margin: 30px;
$extralarge-margin: 40px;

// Mobile screen padding
$mobile-screen-padding-h: $grid-gutter-width;

$promo-feature-item-padding: 30px;

$product-intro-padding: 35px;

$page-section-padding: ();

// stylelint-disable-next-line scss/dollar-variable-default
$page-section-padding: map-merge(
  (
    xxxxs: 5px,
    xxxs: 10px,
    xxs: 15px,
    xs: 25px,
    sm: 50px,
    md: 75px,
    lg: 100px,
  ),
  $page-section-padding
);

//Spacing for photo listing module
$photo-item-padding-h: 20px;
$photo-item-padding-v: 20px;

$photo-item-padding-card-h: 10px;
$photo-item-padding-card-v: 10px;

$photo-item-padding-grid-h: 30px;
$photo-item-padding-grid-v: 30px;

$photo-item-padding-grid-card-h: $spacer;
$photo-item-padding-grid-card-v: $spacer;
