.error-page {
  /* Global */
  a {
    text-decoration: none;
    color: $active-blue;

    &:hover {
      text-decoration: underline !important;
    }
  }
  .left {
    text-align: left;
  }
  .center {
    text-align: center !important;
  }
  p {
    margin-top: 1em;
    margin-bottom: 1em;
  }

  /* Header (RACT Logo) */
  #wrapper > #header {
    text-align: center;
    padding-top: 80px;
    padding-bottom: 80px;
    border-bottom: none;
    background: transparent;
  }
  #wrapper > #header .logo {
    max-width: 110px;
    width: 60%;
    height: auto;
  }

  /* Main */
  #main {
    max-width: 800px;
    margin: 0 auto;
    text-align: center;

    h1 {
      padding: 0;
      margin: 0;
      font-size: 50px;
      font-weight: $font-weight-normal;

      span {
        padding-bottom: 26px;
        display: block;
        width: 100%;
        margin: 0;
        font-size: 110px;
        font-weight: $font-weight-bold;
        color: inherit;
      }
    }
  }

  /* Link lists */
  ul.links {
    list-style: none;
    padding: 0 40px;
    width: 660px;
    display: flex;
    flex-wrap: wrap;
    text-align: left;
    margin: 0 auto 80px;
    color: $active-blue;
  }
  ul.links li {
    list-style: none;
    padding: 0;
    flex: 1;
    flex-basis: 50%;
    color: inherit;
  }
  ul.links.left li::before {
    font-family: $font-family-font-awesome;
    content: '\f054';
    font-weight: $font-weight-normal;
    color: inherit;
    margin-right: 10px;
    font-size: 24px;
    position: relative;
    top: 1px;
  }
  ul.links li > a {
    font-size: 23px;
    text-decoration: none;
    color: inherit;
    font-weight: $font-weight-normal;
  }
  ul.links span {
    display: block;
    width: 100%;
  }
  ul.links span a {
    display: block;
    width: 100%;
    font-size: 40px;
    font-weight: $font-weight-bold;
  }
  ul.links.center a {
    padding-bottom: 20px;
  }

  /* Footer */
  #wrapper > footer {
    background: none;
  }
  footer {
    padding: 0 !important;
    background: none;
  }
  footer .social {
    width: 440px;
    margin: 0 auto;
    border-top: 1px solid $gray-800;
    padding-top: 40px;
  }
  footer a {
    display: inline-block;
    width: 40px;
    height: 40px;
    margin-right: 20px;
    opacity: 1;
  }
  footer a:last-child {
    margin-right: 0;
  }
  footer a .icon {
    font-size: 40px;
    color: $gray-800 !important;
  }
  footer a .icon.fa-youtube {
    font-size: 45px;
    position: relative;
    top: 2px;
  }

  /***  RESPONSIVE STYLES  ***/
  @media screen and (max-width: 850px) {
    * {
      font-size: 22px;
      line-height: 36px;
    }
    header {
      padding: 40px 0;
    }
    #main h1 {
      font-size: 30px;
      line-height: 20px;
    }
    #main h1 span {
      font-size: 80px;
    }
    ul.links {
      width: 560px;
      margin: 0 auto 40px;
    }
    ul.links li > a {
      font-size: 18 px;
    }
    ul.links span a {
      font-size: 30px;
    }
    ul.links.left li::before {
      font-size: 18px;
      position: relative;
      top: 1px;
    }
    footer a {
      margin-right: 6px;
    }
    footer a .icon {
      font-size: 30px;
    }
    footer a .icon.fa-youtube {
      font-size: 34px;
      position: relative;
      top: 2px;
    }
  }
  @media screen and (max-width: 650px) {
    ul.links {
      display: block;
      width: 240px;
      padding: 0;
    }
    footer div.social {
      width: 80%;
    }
  }
}
