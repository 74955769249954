.search-hits {
  list-style: none;
}

.search-hits-item {
  a {
    text-decoration: none;
    color: $active-blue;

    &:hover {
      text-decoration: underline;
    }
  }
}

.search-hits-item-breadcrumbs {
  list-style-type: none;
  color: $gray-400;

  li {
    &::after {
      content: '>';
      display: block;
      margin: 0 $spacer * 0.5;
    }

    &:last-child {
      &::after {
        display: none;
      }
    }
  }
}
