$search-results-max-widths: (
  sm: 540px,
  md: 540px,
  lg: 720px,
  xl: 960px,
);

.search-page {
  @include make-container();
  @include make-container-max-widths($container-max-widths-narrow);

  margin-bottom: map-get($page-section-padding, lg) * 2;
}

.searchbox {
  display: flex;
  padding-top: map-get($page-section-padding, xs);
  padding-bottom: map-get($page-section-padding, xs);

  @include media-breakpoint-up(md) {
    padding-top: map-get($page-section-padding, sm);
    padding-bottom: map-get($page-section-padding, sm);
  }
}

.searchbox-input {
  display: flex;
  background-color: $gray-50;
  height: rem(56px);
  border-radius: 50rem 0 0 50rem;
  vertical-align: middle;
  padding: 0 0.4rem 0 1rem;
  margin-right: 0;
  font-weight: $input-font-weight;
  line-height: $input-line-height;
  color: $input-color;
  border: none;
  flex-grow: 1;
  flex-shrink: 1;
  box-shadow: none;

  @include placeholder() {
    font-style: italic;
  }
}

.searchbox-submit {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.loading-spinner {
  color: $active-blue;
  height: 2.5em;
  width: 2.5em;
}
