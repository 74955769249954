@use 'sass:math';

.rating {
  color: $white;

  &.one {
    background-color: $ract-red;
  }

  &.two {
    background-color: #f06525;
  }

  &.three {
    background-color: $orange;
  }

  &.four {
    background-color: #d5b41c;
  }

  &.five {
    background-color: $lime;
  }

  &.six {
    background-color: #93ae00;
  }
}

.rating-title {
  max-width: 80px !important;
}

.rating-content {
  width: 80% !important;
  text-align: left !important;
}

.key-road-priority-wrapper {
  .component-content {
    @include media-breakpoint-down(md) {
      overflow-x: scroll;
    }
  }
}

table.pricing {
  width: 100%;
  color: $gray-800;
  margin-bottom: $extralarge-margin * 2;
  border-collapse: collapse;

  .no-bkg {
    background-color: $white !important;
    border: none !important;
  }

  th,
  td {
    padding: map-get($page-section-padding, xxxs);
    vertical-align: middle;
    border: 1px solid $white;
    text-align: center;
  }

  th {
    background: $active-blue;
    color: $white;

    &.title {
      text-align: right;
      background: none;
      border: 1px solid $gray-100;
      border-left: none;
      border-right: none;
      color: $gray-800;
    }
  }

  tr {
    &.product-title th {
      background: $midnight-blue;
      color: $white;
      border-top-right-radius: math.div($leaf-radius, 2);
      border-top-left-radius: math.div($leaf-radius, 2);
    }

    &.title-row {
      display: none;

      th {
        vertical-align: bottom;
        padding-bottom: 2px;

        @include media-breakpoint-down(md) {
          text-align: left;
          background: $white;
          color: $gray-800;
        }
      }

      @include media-breakpoint-down(md) {
        display: table-row;
      }
    }

    &.product-period {
      th:first-child {
        background: $gray-50;
        text-align: center;
      }

      @include media-breakpoint-down(sm) {
        th {
          font-size: 14px;
        }
      }
    }

    @include media-breakpoint-down(sm) {
      td,
      th {
        padding: map-get($page-section-padding, xxxs) 4px;
      }
    }
  }

  td {
    background: $gray-50;
    padding: map-get($page-section-padding, xxxs) map-get($page-section-padding, xxs);
    hyphens: manual;

    span {
      display: block;
      color: #767c88;
    }
  }

  tbody {
    tr > *:nth-child(1) {
      width: 30%;
    }
    tr > *:nth-child(2) {
      width: 20%;
    }
    tr > *:nth-child(3) {
      width: 20%;
    }
    tr > *:nth-child(4) {
      width: 20%;
    }
    tr > *:nth-child(5) {
      width: 10%;
    }

    @include media-breakpoint-down(md) {
      tr > *:nth-child(2) {
        width: 30%;
      }

      tr > *:nth-child(3) {
        width: 30%;
      }

      tr > *:nth-child(4) {
        width: 30%;
      }

      tr > *:nth-child(5) {
        width: 10%;
      }
    }
  }

  @include media-breakpoint-down(md) {
    .title {
      border: 0;
      clip: rect(0, 0, 0, 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px !important;
    }
  }
}

table.legend {
  margin: 0 auto $extralarge-margin * 3;

  @include media-breakpoint-down(md) {
    margin: 0 auto $large-margin * 2;
  }
}
