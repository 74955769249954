.width-normal {
  > .component-content {
    @include make-container();
    @include make-container-max-widths($container-max-widths);

    @include media-breakpoint-down(xl) {
      max-width: map-get($container-max-widths, lg) - 15px;
    }

    @include media-breakpoint-down(lg) {
      max-width: none;
    }

    @include media-breakpoint-down(sm) {
      &.block-content {
        padding: 0;
      }
    }
  }
}

.width-narrow {
  > .component-content {
    @include make-container();
    @include make-container-max-widths($container-max-widths-narrow);

    @include media-breakpoint-down(sm) {
      &.block-content {
        padding: 0;
      }
    }
  }
}

// Set the width of an inner element in a row splitter to allow for columns
.row-splitter > .container-fluid {
  &.width-normal > div {
    @include make-container();
    @include make-container-max-widths();
  }

  &.width-narrow > div {
    @include make-container();
    @include make-container-max-widths($container-max-widths-narrow);
  }
}

// affects homepage only
.ract-home {
  .width-normal {
    > .component-content {
      @include make-container();

      @include media-breakpoint-down(xl) {
        max-width: map-get($container-max-widths, lg) - 15px;
      }

      @include media-breakpoint-down(xl) {
        max-width: map-get($container-max-widths, lg) - 15px;
      }

      @include media-breakpoint-down(lg) {
        max-width: none;
      }
    }
  }
}
