$icon-item-padding-h: 60px;
$icon-item-padding-v: 50px;
$icon-item-padding-sm-v: 30px;
$icon-item-padding-xs-v: 20px;
$list-padding-h: 80px;
$list-padding-sm-h: 30px;

.icon-link-list.component.grid-social {
  .component-content {
    ul {
      margin-bottom: -$icon-item-padding-v;
      padding: 0 $list-padding-h;
      justify-content: center;

      .icon-wrapper {
        text-align: center;
      }
    }
    li {
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: auto;
      width: 25%;
      padding-bottom: $icon-item-padding-v;

      .icon-wrapper {
        font-size: 5rem;
        .fa-fw {
          width: auto;
        }
      }
    }

    @include media-breakpoint-down(sm) {
      ul {
        padding: 0 $list-padding-sm-h;
        margin-bottom: -$icon-item-padding-xs-v;
      }
      li {
        width: 50%;
        padding-right: 0;
        padding-bottom: $icon-item-padding-xs-v;
      }
    }
  }
}
