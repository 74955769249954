.promotion-tile.component {
  display: flex;

  .component-content {
    width: 100%;
  }

  .promotion-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    padding: $promo-feature-item-padding;
    padding-bottom: rem(60px);
    border-top-left-radius: $leaf-radius-md;
    border-bottom-right-radius: $leaf-radius-md;

    @include media-breakpoint-down(sm) {
      border-top-left-radius: $leaf-radius-sm;
      border-bottom-right-radius: $leaf-radius-sm;
      padding-top: rem(100px);
    }

    .width-full & {
      padding: 50px $promo-feature-item-padding;
    }
  }

  .hundred-year {
    background-color: $hundred-year-midnight-blue;
    a {
      background-color: $hundred-year-gold;
      border-color: $hundred-year-gold;
      color: $white;
      &:hover {
        background-color: $hundred-year-gold-hover;
        border-color: $hundred-year-gold-hover;
      }
    }
  }
}

.row-splitter .promotion-tile.component .promotion-wrapper {
  padding-top: rem(105px);
}
