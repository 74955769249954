// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// Color system

$blue: $active-blue;
$red: #dc3545;

// Spacing
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 1rem !default;
$spacers: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge(
  (
    0: 0,
    1: (
      $spacer * 0.25,
    ),
    2: (
      $spacer * 0.5,
    ),
    3: $spacer,
    4: (
      $spacer * 1.5,
    ),
    5: (
      $spacer * 3,
    ),
    6: (
      $spacer * 4.75,
    ),
  ),
  $spacers
);

// // Body
// //
// // Settings for the `<body>` element.
$body-color: $gray-500;

// // Links
// //
// // Style anchor elements.
$link-decoration: underline; //none !default;

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: ();
// stylelint-disable-next-line scss/dollar-variable-default
$grid-breakpoints: map-merge(
  (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1366px,
  ),
  $grid-breakpoints
);

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: ();
// stylelint-disable-next-line scss/dollar-variable-default
$container-max-widths: map-merge(
  (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px,
    xxl: 1280px,
  ),
  $container-max-widths
);

$container-max-widths-narrow: ();
// stylelint-disable-next-line scss/dollar-variable-default
$container-max-widths-narrow: map-merge(
  (
    sm: 540px,
    md: 720px,
    lg: 720px,
    xl: 820px,
    xxl: 960px,
  ),
  $container-max-widths-narrow
);

// // Grid columns
// //
// // Set the number of columns and specify the width of the gutters.

// // Components

// Fonts
//
// Font, line-height, and color for body text, headings, and more.

// stylelint-disable value-keyword-case
$font-family-sans-serif: 'Karla', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue',
  Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
$font-family-secondary: 'Poppins', sans-serif;
$font-family-font-awesome: 'Font Awesome 5 Pro', sans-serif;
// stylelint-enable value-keyword-case

$font-size-xl: rem(24px);
$font-size-lg: rem(22px);
$font-size-lg-ish: rem(20px);
$font-size-base: rem(18px);
$font-size-sm-ish: rem(16px);
$font-size-sm: rem(14px);
$font-size-xs: rem(12px);

$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-bold: 600;
$font-weight-bolder: 900;

$font-weight-base: $font-weight-normal;
$line-height-base: 1.5;

$h1-font-size: rem(60px); //$font-size-base * 2.5 !default;
$h2-font-size: rem(50px); //$font-size-base * 2 !default;

$headings-margin-bottom: 1rem; //$spacer / 2 !default;
$headings-font-family: $font-family-secondary;
$headings-font-weight: $font-weight-medium;
$headings-line-height: 1.3;
$headings-color: $gray-500;

$lead-font-size: 1.4em; //($font-size-base * 1.25) !default;

$small-font-size: 75%; //80% !default;

$dt-font-weight: $font-weight-bold !default;

// // Tables
// //
// // Customizes the `.table` component with basic values, each used across all table variations.

// // Buttons + Forms
// //
// // Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

$input-btn-padding-y: rem(16px); //.375rem !default;
$input-btn-padding-x: rem(30px); //.75rem !default;
$input-btn-line-height: 1.2; //$line-height-base !default;

$input-btn-padding-y-sm: rem(11px); //.25rem !default;
$input-btn-padding-x-sm: rem(22px); //.5rem !default;
$input-btn-line-height-sm: 1.2; //$line-height-sm !default;

$input-btn-padding-y-lg: rem(20px); //.5rem !default;
$input-btn-padding-x-lg: rem(50px); //1rem !default;
$input-btn-font-size-lg: rem(26px); //$font-size-lg !default;
$input-btn-line-height-lg: 1.2; //$line-height-lg !default;

$input-btn-border-width: 2px; //$border-width !default;

// // Buttons
// //
// // For each of Bootstrap's buttons, define text, background, and border color.

$btn-line-height: rem(20px);
$btn-font-weight: $font-weight-normal;

// // Allows for customizing button radius independently from global border radius
$btn-border-radius: 50rem;
$btn-border-radius-lg: $btn-border-radius;
$btn-border-radius-sm: $btn-border-radius;

// Custom settings
$gutter: 25px;
$gutter-sm: 25px;
$gutter-md: 50px;
$gutter-lg: 50px;
$gutter-xl: 50px;
$gutter-xxl: 75px;
