@font-face {
  font-family: 'Karla';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/karla-v15-latin-regular.woff2');
  font-display: swap;
}

@font-face {
  font-family: 'Karla';
  font-style: italic;
  font-weight: 400;
  src: url('/fonts/karla-v15-latin-italic.woff2');
  font-display: swap;
}

@font-face {
  font-family: 'Karla';
  font-style: normal;
  font-weight: 700;
  src: url('/fonts/karla-v15-latin-700.woff2');
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  src: url('/fonts/poppins-v15-latin-500.woff2');
  font-display: swap;
}

body {
  // defaults to $font-size-base
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @include media-breakpoint-up(sm) {
    font-size: 18px;
  }

  @include media-breakpoint-up(lg) {
    font-size: 22px;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin: 0;
  font-family: $headings-font-family;
  font-weight: $headings-font-weight;
  line-height: $headings-line-height;
  color: $headings-color;
}

.display-1 {
  font-size: 38px;

  @include media-breakpoint-up(sm) {
    font-size: 44px;
  }

  @include media-breakpoint-up(lg) {
    font-size: 60px;
  }
}

h1,
.h1 {
  font-size: 32px;

  @include media-breakpoint-up(sm) {
    font-size: 38px;
  }

  @include media-breakpoint-up(lg) {
    font-size: 50px;
  }
}

h2,
.h2 {
  font-size: 26px;

  @include media-breakpoint-up(sm) {
    font-size: 32px;
  }

  @include media-breakpoint-up(lg) {
    font-size: 44px;
  }
}

h3,
.h3 {
  font-size: 20px;

  @include media-breakpoint-up(sm) {
    font-size: 26px;
  }

  @include media-breakpoint-up(lg) {
    font-size: 38px;
  }
}

h4,
.h4 {
  font-size: 20px;

  @include media-breakpoint-up(sm) {
    font-size: 20px;
  }

  @include media-breakpoint-up(lg) {
    font-size: 32px;
  }
}

h5,
.h5 {
  font-size: 20px;

  // Duplicate to ensure class overrides other heading element styles
  @include media-breakpoint-up(sm) {
    font-size: 20px;
  }

  @include media-breakpoint-up(lg) {
    font-size: 26px;
  }
}

h6,
.h6 {
  font-size: 20px;

  // Duplicate to ensure class overrides other heading element styles
  @include media-breakpoint-up(sm) {
    font-size: 20px;
  }

  @include media-breakpoint-up(lg) {
    font-size: 20px;
  }
}

.body-1 {
  font-size: 18px;

  @include media-breakpoint-up(sm) {
    font-size: 18px;
  }

  @include media-breakpoint-up(lg) {
    font-size: 26px;
  }
}

.body-2 {
  font-size: 18px;

  @include media-breakpoint-up(sm) {
    font-size: 18px;
  }

  @include media-breakpoint-up(lg) {
    font-size: 24px;
  }
}

.body-3 {
  font-size: 18px;

  @include media-breakpoint-up(sm) {
    font-size: 18px;
  }

  @include media-breakpoint-up(lg) {
    font-size: 22px;
  }
}

.body-4 {
  font-size: 16px;

  @include media-breakpoint-up(sm) {
    font-size: 16px;
  }

  @include media-breakpoint-up(lg) {
    font-size: 20px;
  }
}

.body-5 {
  font-size: 16px;

  @include media-breakpoint-up(sm) {
    font-size: 16px;
  }

  @include media-breakpoint-up(lg) {
    font-size: 18px;
  }
}

.body-6 {
  font-size: 16px;

  @include media-breakpoint-up(sm) {
    font-size: 16px;
  }

  @include media-breakpoint-up(lg) {
    font-size: 16px;
  }
}

.body-7 {
  font-size: 14px;

  @include media-breakpoint-up(sm) {
    font-size: 14px;
  }

  @include media-breakpoint-up(lg) {
    font-size: 14px;
  }
}

.body-8 {
  font-size: 12px;

  @include media-breakpoint-up(sm) {
    font-size: 12px;
  }

  @include media-breakpoint-up(lg) {
    font-size: 12px;
  }
}

small,
.small {
  font-size: $small-font-size;
}

//
//  Misc
//

// a button that is always at least 200px wide
.btn-min-size {
  min-width: 200px;
  @include media-breakpoint-only(xs) {
    width: 100%;
  }
}

.module-description {
  text-align: left;
  max-width: 100%;
}

// popover styles
.popover-header {
  margin-top: 0;
}
