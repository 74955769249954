.pagination {
  padding-top: map-get($spacers, 5);
  padding-bottom: map-get($spacers, 5);
}

.pagination-list {
  display: flex;
  justify-content: center;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.pagination-list-item {
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination-link {
  display: flex;
  padding: $pagination-padding-y $pagination-padding-x;
  font-size: 30px;
  text-decoration: none;
  position: relative;
  color: $gray-400;
  transition: color 100ms ease-in-out;
  text-align: center;

  &:hover,
  &:focus {
    text-decoration: none !important;
    color: $active-blue;
  }

  .is-prev &,
  .is-next & {
    font-size: 26px;
  }

  .is-number & {
    border-bottom: 1px solid $gray-400;
    font-variant-numeric: tabular-nums;
    min-width: 45px; // provides more symmetry across differing number widths

    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 3px;
      bottom: -1px;
      left: 0;
      background: $active-blue;
      opacity: 0;
      transition: opacity 100ms ease-in-out;
    }
  }

  &.is-active {
    color: $list-pagination-active-color;
    position: relative;

    &::after {
      opacity: 1;
    }
  }

  &.is-disabled {
    &:hover {
      color: $gray-400;
    }
  }
}
