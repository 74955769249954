// Custom styles for the layout of the Article page design

.article-page {
  .article-header {
    padding-top: map-get($page-section-padding, sm);

    @include media-breakpoint-up(md) {
      padding-top: map-get($page-section-padding, md);
    }

    @include media-breakpoint-up(lg) {
      padding-top: map-get($page-section-padding, lg);
    }
  }
}
