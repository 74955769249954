.notice-container {
  background-color: $bg-yellow;
  text-align: center;
  overflow: hidden;
  position: relative;
}

.notice-title {
  color: $text-dark-yellow;
  margin-top: $middle-margin;
  margin-bottom: $middle-margin;
}

#after-hours-assistance {
  display: none;
}
