// Max widths and gutters
main {
  margin: 0 $gutter 25px;

  @include media-breakpoint-up(lg) {
    margin: 0 $gutter-lg 50px;
  }

  @include media-breakpoint-up(xxl) {
    margin: 0 $gutter-xxl 50px;
  }
}

#content {
  max-width: map-get($grid-breakpoints, xxl);
  padding-left: 0;
  padding-right: 0;

  > .row {
    max-width: none;
    display: block;
  }

  .row > .row {
    margin: 0;
  }

  .content .row {
    max-width: none;
  }
}

// Enlarge the bootsrap grid container for XXL screens

@media (min-width: map-get($grid-breakpoints, xxl)) {
  .container {
    max-width: map-get($container-max-widths, xxl);
  }
}

// Prevent page scrolling when the mobile menu is open
.prevent-scroll {
  overflow: hidden;
}

// Apply a fixed position to the main body content when the mobile menu is shown to negate the header scrolling out of view.
.drawer-open {
  position: fixed;
}

// Apply a fixed position and opacity to the footer content when the mobile menu is shown to negate the header scrolling out of view.
.drawer-open-footer {
  position: fixed;
  opacity: 0;
}

// may cause issues if we have any snippets that aren't multi-columns
$col-spacer: map-get($spacers, 5);
.row-splitter,
.snippet {
  .component {
    @include media-breakpoint-down(sm) {
      + .col-sm-12:not(.order-first) {
        margin-top: $col-spacer;
      }
      + .col-sm-12.order-first {
        margin-bottom: $col-spacer;
      }
    }
    @include media-breakpoint-down(md) {
      + .col-md-12:not(.order-first) {
        margin-top: $col-spacer;
      }
      + .col-sm-12.order-first {
        margin-bottom: $col-spacer;
      }
    }
    @include media-breakpoint-down(lg) {
      + .col-lg-12:not(.order-first) {
        margin-top: $col-spacer;
      }
      + .col-sm-12.order-first {
        margin-bottom: $col-spacer;
      }
    }
    @include media-breakpoint-up(md) {
      + .col-sm-12.order-first {
        margin-bottom: 0 !important;
      }
    }
  }
}

// min-height styles used by other page templates across the site
.page-banner.photo-bg {
  // default heights of a banner with no specified size
  min-height: $banner-min-height-sm;
  margin-left: -$gutter;
  border-bottom-right-radius: $leaf-radius-sm;

  img {
    border-bottom-right-radius: $leaf-radius-sm;
  }

  @include media-breakpoint-up(md) {
    border-bottom-right-radius: $leaf-radius-md;
    min-height: $banner-tall-min-height-md;

    img {
      border-bottom-right-radius: $leaf-radius-md;
    }
  }

  @include media-breakpoint-up(lg) {
    min-height: $banner-tall-min-height-lg;
    margin-left: -$gutter-lg;
    width: calc(100% + #{$gutter-lg});
  }

  @include media-breakpoint-up(xxl) {
    margin-left: 0;
    width: 100%;
  }

  // short banner height
  &.banner-short {
    min-height: 100px;
  }

  // medium banner height
  &.banner-medium {
    min-height: $banner-min-height-sm;
    @include media-breakpoint-up(md) {
      min-height: $banner-min-height-md;
    }
    @include media-breakpoint-up(lg) {
      min-height: $banner-min-height-lg;
    }
  }

  // tall banner height
  &.banner-tall {
    min-height: $banner-tall-min-height-sm;
    @include media-breakpoint-up(md) {
      min-height: $banner-tall-min-height-md;
    }
    @include media-breakpoint-up(lg) {
      min-height: $banner-tall-min-height-lg;
    }
  }
}

.content-blocks-item {
  padding-bottom: map-get($page-section-padding, sm);

  &.bg-alt {
    width: 100%;
    margin-right: map-get($page-section-padding, xxs);
    margin-left: map-get($page-section-padding, xxs);

    &:not(.is-same-theme-as-prev) {
      border-top-left-radius: $leaf-radius-sm;
    }

    &:not(.is-same-theme-as-next) {
      border-bottom-right-radius: $leaf-radius-sm;
    }

    @include media-breakpoint-up(md) {
      &:not(.is-same-theme-as-prev) {
        border-top-left-radius: $leaf-radius-md;
      }

      &:not(.is-same-theme-as-next) {
        border-bottom-right-radius: $leaf-radius-md;
      }
    }
  }

  &:not(.is-same-theme-as-prev) {
    padding-top: map-get($page-section-padding, sm);

    @include media-breakpoint-up(md) {
      padding-top: map-get($page-section-padding, md);
    }

    @include media-breakpoint-up(lg) {
      padding-top: map-get($page-section-padding, lg);
    }
  }

  @include media-breakpoint-up(md) {
    padding-bottom: map-get($page-section-padding, md);
  }

  @include media-breakpoint-up(lg) {
    padding-bottom: map-get($page-section-padding, lg);
  }
}
