$product-banner-overlap: $h1-font-size + rem(25px);

.ract-product {
  .product-container {
    position: relative;
    display: flex;

    .field-wrapper {
      background: $active-blue;
      padding: map-get($page-section-padding, sm);
      flex: 0 0 auto;
      margin-right: $product-intro-padding;
      width: 61.8%;
      border-top-left-radius: $leaf-radius-sm;
      border-bottom-right-radius: $leaf-radius-sm;

      @include media-breakpoint-up(md) {
        border-top-left-radius: $leaf-radius-md;
        border-bottom-right-radius: $leaf-radius-md;
      }

      ul {
        list-style: none;
        margin-top: map-get($spacers, 4);
        padding-left: 0;

        li {
          color: $light-blue;

          .hundred-year-theme {
            color: $hundred-year-gold;
          }
        }
      }

      div > a.hundred-year-theme.btn-white {
        background-color: $hundred-year-gold;
        border-color: $hundred-year-gold;
        color: $white;
        &:hover {
          background-color: $hundred-year-gold-hover;
          border-color: $hundred-year-gold-hover;
        }
      }

      div > a.hundred-year-theme.btn-outline-white {
        background-color: $hundred-year-midnight-blue;
        border-color: $hundred-year-gold;
        color: $white;
        &:hover {
          background-color: $hundred-year-gold;
          border-color: $hundred-year-gold;
        }
      }
    }

    .field-wrapper.hundred-year-theme {
      background: $hundred-year-midnight-blue;
    }

    .product-links {
      flex-grow: 1;
      flex-shrink: 1;
      align-self: flex-end;
      overflow: hidden; // required to fix a bug in IE - https://github.com/philipwalton/flexbugs/issues/170
      border-top-left-radius: $leaf-radius-sm;
      border-bottom-right-radius: $leaf-radius-sm;

      // ensure some whitespace above
      margin-top: $product-banner-overlap + rem($product-intro-padding);

      @include media-breakpoint-up(md) {
        border-top-left-radius: $leaf-radius-md;
        border-bottom-right-radius: $leaf-radius-md;
      }

      // bring the right margin inline with narrow containers
      @include media-breakpoint-up(lg) {
        margin-right: map-get($page-section-padding, xs);
      }
    }

    .product-link-wrapper {
      background: $ract-blue;
      display: flex;
      flex-direction: column;
      padding: map-get($page-section-padding, sm);

      .product-link-group {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        a {
          color: $white;
          display: block;
          text-decoration: none;
          flex-wrap: wrap;
          line-height: 1.2;

          &:hover {
            text-decoration: underline;
          }
        }

        &:not(:last-child) {
          margin-bottom: $spacer;
        }
      }
    }

    .product-link-wrapper.hundred-year-theme {
      background: $hundred-year-midnight-blue;
    }

    &.has-hero-image {
      margin-top: $middle-margin;

      @include media-breakpoint-up(md) {
        margin-top: (0 - $product-banner-overlap);
      }
    }
  }

  @include media-breakpoint-down(md) {
    .product-container.container {
      max-width: unset;
    }

    .product-container {
      position: relative;
      flex-direction: column;

      .field-wrapper {
        margin: 0;
        width: 100%;
        padding: map-get($page-section-padding, sm) - map-get($page-section-padding, xxxs);
      }

      .product-links {
        width: unset;
        margin: ($middle-margin + 10px) 0;
        align-self: unset;
      }

      .product-link-wrapper {
        padding: map-get($page-section-padding, sm) - map-get($page-section-padding, xxxs);
      }
    }
  }
}
