.cta-grid.with-background-colors {
  .component-content {
    ul {
      margin-bottom: -10px;
      justify-content: center;

      li {
        padding-bottom: 10px;

        .item-wrapper {
          padding: 3rem 1rem;
          justify-content: center;
          min-height: 214px;

          @include media-breakpoint-up(md) {
            min-height: 0;
          }
        }

        @include media-breakpoint-up(sm) {
          width: 100%;
        }

        @include media-breakpoint-up(md) {
          width: 50%;
          padding-left: 5px;
          padding-right: 5px;
        }

        @include media-breakpoint-up(lg) {
          width: 33.3333%;
        }
      }

      @include media-breakpoint-up(md) {
        margin-left: -5px;
        margin-right: -5px;
      }
    }
  }
}
