.icon-link-list.component.link-list-with-icons {
  .component-content {
    ul {
      display: flex;
      flex-wrap: wrap;
      padding-left: 0;
      list-style-type: none;

      // extra specificity required here
      li a {
        display: block;
        color: inherit;
        transition: transform 100ms;
        text-decoration: none;

        @include hover-focus() {
          text-decoration: none;
          transform: scale(1.05);
          transition: transform 200ms;

          .field-title {
            text-decoration: underline;
          }
        }
      }
    }

    @include media-breakpoint-down(xs) {
      ul {
        margin-right: 0;
      }
    }
  }
}

@import 'variants/icon-link-list/default';
@import 'variants/icon-link-list/list';
@import 'variants/icon-link-list/grid';
@import 'variants/icon-link-list/grid-social';
