// Prevent the font-size from increasing on larger screens
.ract-form {
  font-size: $font-size-base;
}

.ract-form,
.form-group {
  label {
    font-weight: $font-weight-normal;
    font-size: 16px;
  }

  .form-control {
    border-color: #e6e6e6;
    padding: 1rem;
    font-size: 16px;

    ::placeholder {
      color: #e6e6e6;
    }
  }

  .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  .react-autosuggest__suggestion {
    cursor: pointer;
    padding: 0.5rem 1rem;
    border-bottom: 1px solid $gray-400;
    border-left: 1px solid $gray-400;
    border-right: 1px solid $gray-400;
    :hover {
      color: $active-blue;
    }
  }

  .registration-numbers-counter-label {
    margin: 0;
  }

  .registration-numbers-counter {
    margin-bottom: 2rem;
    font-size: $font-size-xl;
  }

  .registration-numbers-list {
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      justify-content: center;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 20px;
      margin-bottom: 20px;

      @include media-breakpoint-up(md) {
        grid-template-columns: repeat(5, 1fr);
      }

      .form-group {
        margin-bottom: 0;
      }
    }
  }
}

// Quick fix for custom forms to force full-width
.form-full-width {
  width: 100%;
}

.form-group.validation-error {
  .form-control {
    border-color: $red;
    padding-right: 2.25rem;
    background-repeat: no-repeat;
    background-position: center right calc(2.25rem / 4);
    background-size: calc(2.25rem / 2) calc(2.25rem / 2);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23d9534f' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");

    &:focus {
      border-color: $red;
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
    }
  }

  .form-check-input ~ .form-check-label {
    color: $red;
  }

  textarea.form-control {
    padding-right: 2.25rem;
    background-position: top calc(2.25rem / 4) right calc(2.25rem / 4);
  }

  .field-validation-error {
    display: block;
    margin-top: 0.25rem;
    font-size: 80%;
    color: $red;
  }
}

.form-submission-error {
  margin-top: $spacer;
  color: $red;
}
