#calc {
  .calc-container {
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
    background: #eff1f5;
    padding: 40px;
    content: '';
    display: table;
    clear: both;
    box-sizing: border-box;

    p {
      font-size: 16px !important;
      text-align: center;
      color: #777 !important;
      line-height: 20px !important;
      margin-bottom: 4px !important;
    }

    p span#repayment_amount {
      display: block;
      width: 100%;
      font-size: 40px !important;
      font-weight: $font-weight-bold;
      color: #2376d8 !important;
      line-height: 50px !important;
    }
  }

  #loan-types {
    padding: 0;
    gap: 1px;
    background: $gray-200;
    margin-top: 30px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    .loan-types-icon {
      font-size: 40px;
      margin-bottom: 6px;
    }

    div {
      text-align: center;
      padding: 10px;
      font-weight: $font-weight-bold;
      color: #2376d8;
      cursor: pointer;
      font-size: 14px;
      line-height: 13px;
      background: $white;
    }

    div span {
      font-size: 10px;
    }

    div img {
      max-width: 40px;
    }

    div.selected {
      background: #2376d8;
      cursor: default;
      color: $white;
    }
  }

  #form {
    float: left;
    width: 70%;
    box-sizing: border-box;
  }

  .switch {
    width: 27%;
    margin-left: 3%;
    float: left;
  }

  label {
    width: 100%;
    font-size: 16px;
    display: inline-block;
    color: #144783;
    position: relative;
    top: 10px;
  }

  label.error {
    color: red;
    font-size: 11px;
    width: 100%;
    text-align: right;
    padding-right: 3%;
    position: relative;
    top: -10px;
  }

  input[type='text'] {
    padding: 10px;
    width: 45%;
    background: $white;
    border: 2px solid #cad1e0;
    margin-left: 3%;
    font-size: 16px;
    color: #777;
    position: relative;
    top: -10px;
    border-radius: 4px;
  }

  a.butt {
    display: block;
    width: 90%;
    padding: 10px 20px;
    border: 2px solid #2376d8;
    text-align: center;
    text-decoration: none;
    border-radius: 50px;
    color: #2376d8 !important;
    font-size: 18px;
    margin: 10px auto;
    transition: all 0.3s;
  }

  a.butt.selected {
    border-color: #2376d8;
    background: #2376d8 !important;
    color: $white !important;
    cursor: default;
  }

  a.butt:hover {
    background: rgba(35, 118, 216, 0.2);
    cursor: pointer;
  }

  .monthly-butt {
    margin-top: 0 !important;
  }

  hr {
    width: 70%;
    background-color: none;
    border: 0;
    height: 0;
    border-bottom: 1px solid #ddd;
  }

  .slider {
    width: 45% !important;
    display: inline-block !important;
    -webkit-appearance: none;
    appearance: none;
    height: 40px; /* Specified height */
    outline: none; /* Remove outline */
    -webkit-transition: 0.2s; /* 0.2 seconds transition on hover */
    transition: 0.2s;
    opacity: 0.2s;
    position: relative;
    top: 8px;
    background: none;
    padding-top: 0;
    padding-bottom: 5px;
  }

  .slider:hover {
    opacity: 1;
  }

  .slider::-moz-range-thumb {
    appearance: none;
    width: 25px; /* Set a specific slider handle width */
    height: 25px; /* Slider handle height */
    background: #144783;
    cursor: pointer; /* Cursor on hover */
    border-radius: 50px;
    border: none;
  }

  .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 25px; /* Set a specific slider handle width */
    height: 25px; /* Slider handle height */
    background: #144783;
    cursor: pointer; /* Cursor on hover */
    border-radius: 50px;
    border: none;
    position: relative;
    top: -10px;
  }

  .slider::-ms-thumb {
    -webkit-appearance: none;
    width: 25px; /* Set a specific slider handle width */
    height: 25px; /* Slider handle height */
    background: #144783;
    cursor: pointer; /* Cursor on hover */
    border-radius: 50px;
    border: none;
  }

  .slider::-moz-range-track {
    width: 100%;
    height: 5px;
    cursor: pointer;
    background: #2376d8;
  }

  .slider::-webkit-slider-runnable-track {
    width: 100%;
    height: 5px;
    cursor: pointer;
    background: #2376d8;
  }

  .slider::-ms-track {
    width: 100%;
    height: 5px;
    cursor: pointer;
    border: none;
    background: transparent;
  }

  input[type='range']::-ms-fill-lower {
    background: #2376d8;
  }

  input[type='range']::-ms-fill-upper {
    background: #2376d8;
  }

  @media screen and (max-width: 720px) {
    #form {
      float: none;
      width: 100%;
    }
    .switch {
      float: none;
      width: 100%;
      margin-left: 0;
      margin-top: 30px;
    }
    .butt {
      float: none;
      width: 100%;
      max-width: 300px;
      margin-left: 0;
    }
  }

  @media screen and (max-width: 520px) {
    #loan-types {
      border: 1px solid $gray-200;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      grid-template-areas:
        'main-option main-option'
        'second-option third-option';
    }

    #loan-types .primary-loan-type {
      grid-area: main-option;
    }

    label {
      width: 100%;
      text-align: center;
    }
    input,
    input[type='text'] {
      width: 100% !important;
      margin-left: 0;
    }
    input.slider {
      margin-top: 0;
      margin-bottom: 20px;
      width: 100% !important;
    }
  }
}
