.article-listing-feature {
  padding: 0 !important;
  .component-content {
    max-width: unset !important;
  }

  .field-heroimage,
  .field-teaserimage {
    max-height: 50vh;
    overflow: hidden;
    text-align: center;

    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }

  + .snippet {
    padding-top: map-get($page-section-padding, sm);
    padding-bottom: map-get($page-section-padding, sm);

    @include media-breakpoint-up(md) {
      padding-top: map-get($page-section-padding, md);
      padding-bottom: map-get($page-section-padding, md);
    }

    @include media-breakpoint-up(lg) {
      padding-top: map-get($page-section-padding, lg);
      padding-bottom: map-get($page-section-padding, lg);
    }

    .page-list.grid {
      // On mobile only, all list-items except the first item will be displayed with a list style
      @include media-breakpoint-down(sm) {
        .items .item:first-child {
          > a {
            @include border-basic(top, $border-gray);
          }

          .article-wrapper {
            flex-direction: column;
          }

          .article-image {
            width: 100%;
            height: 250px;
          }

          .article-container {
            padding-left: 0;
            width: 100%;
          }

          .field-teasertitle {
            padding: 16px 0 26px 0;
          }
        }

        @include grid-to-list();
      }

      @supports not (-ms-grid: grid) {
        @include media-breakpoint-up(lg) {
          .items {
            display: grid;
            grid-template-columns: 5fr 5fr 0.1px 0.1px 5fr 5fr 0.1px 0.1px 5fr 5fr;

            .item {
              position: relative;
              width: unset;
              &:first-child {
                grid-column-start: 1;
                grid-column-end: 3;
              }
              &:nth-child(2) {
                grid-column-start: 5;
                grid-column-end: 7;
              }
              &:nth-child(3) {
                grid-column-start: 9;
                grid-column-end: 11;
              }
              &:nth-child(-n + 3) {
                border: none;
              }
            }
          }
        }
      }
    }
  }

  + .primary-grid-layout {
    .page-list.grid {
      @supports not (-ms-grid: grid) {
        @include media-breakpoint-up(lg) {
          .items {
            .item {
              &:first-child {
                grid-column-start: 1;
                grid-column-end: 6;
                grid-row-start: 1;
                grid-row-end: 3;
                margin: 0 $grid-gutter-width 0 0;
                padding: unset;
                padding-left: 30px;

                .article-image {
                  height: 100%;
                }

                .article-container {
                  height: 52%;
                  position: absolute;
                  background: $white;
                  bottom: 0;
                  width: 45%;
                  padding: 0 20px 20px 30px;
                  justify-content: flex-start;
                }
              }
              &:nth-child(2),
              &:nth-child(3) {
                position: relative;
                grid-column-start: 6;
                grid-column-end: 11;
                padding: unset;
                padding-right: 30px;
                margin: 0;
                flex-direction: row;

                .article-container {
                  position: absolute;
                  background: $white;
                  width: 38%;
                  height: 100%;
                  padding: 0 20px 20px 20px;
                  justify-content: flex-start;
                }
              }
              &:nth-child(2) {
                padding-bottom: $grid-gutter-width;
              }
              &:nth-child(3) {
                margin-top: $grid-gutter-width;

                .article-container {
                  right: 30px;
                }
              }
              &:nth-child(4),
              &:nth-child(5),
              &:nth-child(6) {
                margin-top: 60px;
              }
              &:nth-child(3n + 4) {
                grid-column-start: 1;
                grid-column-end: 3;
              }
              &:nth-child(3n + 5) {
                grid-column-start: 5;
                grid-column-end: 7;
              }
              &:nth-child(3n + 6) {
                grid-column-start: 9;
                grid-column-end: 11;
              }
            }
          }
        }
      }
    }
  }
}
