.journey-collection {
  margin: map-get($page-section-padding, sm) 0;

  .component-content {
    max-width: unset;
    padding: 0;

    @include media-breakpoint-up(md) {
      margin: 0 $gutter-md;
      width: calc(100% - #{$gutter-md});
    }

    @include media-breakpoint-up(lg) {
      margin: 0 $gutter-lg;
      width: calc(100% - #{$gutter-lg});
    }

    @include media-breakpoint-up(xl) {
      margin: 0 $gutter-xl;
      width: calc(100% - #{$gutter-xl});
    }

    @include media-breakpoint-up(xxl) {
      margin: 0 $gutter-xxl;
      width: calc(100% - #{$gutter-xxl});
    }
  }
}

.journey-collection-header {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: $spacer;

  @include media-breakpoint-up(md) {
    margin-bottom: $spacer * 0.75;
    justify-content: space-between;
  }
}

.journey-collection-title {
  color: inherit;
  text-decoration: none;
  margin-bottom: 0;
}

.journey-collection-link {
  display: flex;
  align-items: center;
  color: inherit;
  text-decoration: none;

  &:hover {
    text-decoration: none;
    color: inherit;
  }
}

.journey-collection-list {
  list-style: none;
  padding-left: 0;
  display: flex;
  flex-wrap: wrap;
  margin-left: -5px;
  margin-right: -5px;

  @include media-breakpoint-down(sm) {
    margin-left: 0;
    margin-right: 0;
  }

  @include media-breakpoint-down(xs) {
    margin-right: -$mobile-screen-padding-h;
    margin-left: -$mobile-screen-padding-h;
  }
}

.journey-collection-list-item {
  padding: 0;
  width: 100%;
  height: 330px;
  flex: 1 0 auto;

  @include media-breakpoint-up(md) {
    height: 150px;
    width: 33.333%;
  }

  @include media-breakpoint-up(lg) {
    height: 200px;
  }

  @include media-breakpoint-up(xl) {
    height: 260px;
  }

  // Specific styles for the first full-width journey-list-item
  &:first-child {
    @include media-breakpoint-up(md) {
      height: 255px;
      margin-bottom: 10px;
      width: 100%;
    }

    @include media-breakpoint-up(lg) {
      height: 350px;
    }

    @include media-breakpoint-up(xl) {
      height: 450px;
    }
  }
}

.journey-collection-card {
  position: relative;
  overflow: hidden;
  display: block;
  height: 100%;
  color: $white;

  .image {
    transition: transform ease 700ms;
  }

  @include hover-focus() {
    text-decoration: none !important;

    @include media-breakpoint-up(md) {
      .image {
        transform: scale(1.05);
      }
    }
  }
}

.journey-collection-card-overlay {
  @include linear-gradient(transparent, rgba(0, 0, 0, 0.65));

  color: $white;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: absolute;
  top: 0;
  left: 0;
}

.journey-collection-card-body {
  width: 100%;
  padding: 0 2.5rem 0 1rem;
  transition: transform ease 700ms;

  @include media-breakpoint-up(md) {
    padding: 0 2.2rem;
  }

  @include media-breakpoint-up(xl) {
    padding: 0 3rem;
  }

  // Specific styles for the first full-width journey-list-item
  .journey-collection-list-item:first-child & {
    @include media-breakpoint-up(md) {
      @include hover-focus() {
        transform: translateY(-50%);
      }
    }
  }
}
