.images-block {
  .component-content {
    .images-container {
      flex-wrap: wrap;

      .images-col {
        flex: 33%;
        text-align: center;

        .image {
          margin-top: 8px;
          vertical-align: middle;

          img {
            max-width: 100%;
          }
        }

        figcaption {
          text-align: center;
          margin-top: map-get($spacers, 2);
        }

        @include media-breakpoint-down(lg) {
          flex: 50%;
        }

        @include media-breakpoint-down(md) {
          flex: 100%;
        }
      }
    }
  }
}

.images-block.is-inline > .component-content > .container {
  padding-left: 0;
  padding-right: 0;

  .images-col {
    padding-left: 0;
    padding-right: 0;

    .width-narrow {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.toggle-content .images-block.is-inline .images-col .width-narrow > .component-content {
  padding-left: 0;
}
