/* stylelint-disable */

$iconItemHeight: 28px;
$iconItemMargin: 8px;

#wrapper > footer {
  background: $ract-blue;
  color: $white;
  border-top-left-radius: $leaf-radius-sm;
  border-bottom-right-radius: $leaf-radius-sm;
  margin: 0 $gutter 100px $gutter;
  font-family: $font-family-secondary;

  @include media-breakpoint-up(sm) {
    padding: 0 25px;
  }

  @include media-breakpoint-up(md) {
    border-top-left-radius: $leaf-radius-md;
    border-bottom-right-radius: $leaf-radius-md;
  }

  @include media-breakpoint-up(lg) {
    margin: 0 $gutter-lg 100px $gutter-lg;
    max-width: map-get($grid-breakpoints, xxl);
    padding: 0 50px;
  }

  @include media-breakpoint-up(xxl) {
    position: relative;
    margin: 0 auto 100px auto;
    width: calc(100% - (#{$gutter-xxl + $gutter-xxl}));
    padding: 0 75px;
  }

  @media (max-width: $break-mobile - 1) {
    padding-top: map-get($page-section-padding, sm);
    padding-bottom: map-get($page-section-padding, sm);
  }
  @media (min-width: $break-mobile) {
    padding-top: map-get($page-section-padding, md);
    padding-bottom: map-get($page-section-padding, md);
  }

  .full-width {
    width: 100%;
  }

  .footer-columns {
    margin-bottom: 1rem;
  }

  .footer-heading {
    appearance: none;
    padding: 0;
    background: transparent;
    border: none;
    color: $white;
  }

  a {
    color: $white;
    opacity: 0.65;
    transition: opacity 150ms ease-in-out;

    &:hover,
    &:focus {
      opacity: 1;
      text-decoration: none;
    }
  }

  .link-list {
    > .component-content {
      ul {
        display: none;
        padding-left: 0;
        line-height: 1.15;

        &.is-active {
          display: block;
        }

        @include media-breakpoint-up(md) {
          display: block;
        }
      }

      li {
        margin-bottom: $spacer;
        line-height: 1.2;
      }

      a {
        padding-left: 0;
        font-weight: $font-weight-normal;

        &:hover,
        &:focus {
          border: none;
        }
      }
    }
  }

  .component-content {
    // Social icons used in the footer
    ul.hover-icons {
      list-style: none;
      padding-left: 0;
      margin-right: -8px;

      li {
        display: inline-block;
        margin: 0 0 0.4rem 0;
        padding: 0 $iconItemMargin;
      }

      a {
        position: relative;
        color: $white;
        font-size: $iconItemHeight;
      }
    }
    ul.hover-icons.hundred-year-theme > li > a {
      color: $hundred-year-gold;
      opacity: 1;
    }
  }

  @include media-breakpoint-down(xs) {
    // Add some padding around the footer content for mobile
    .footer-columns {
      padding-left: $mobile-screen-padding-h;
      padding-right: $mobile-screen-padding-h;
    }
  }

  @include media-breakpoint-down(sm) {
    .link-list .component-content {
      border-top: 1px solid mix($midnight-blue, $white, 70%);
      border-bottom: 1px solid mix($midnight-blue, $white, 70%);
      margin-bottom: -1px;
    }

    .hover-icons {
      margin-top: 1rem;
    }
  }

  // Drop the social links to a new line for medium devices
  @include media-breakpoint-down(md) {
    .footer-columns {
      .component.col-12.social-links {
        width: 100%;
        padding-top: 1rem;
        h3 {
          display: none;
          margin-right: 0.5rem;
        }
        .social-links {
          text-align: center;
        }
        ul {
          margin-bottom: 0;
        }
      }

      & + .container-fluid {
        text-align: center;
        p {
          max-width: 270px; // break copyright text on to two lines
          margin: 0 auto;
        }
      }
    }
  }

  @include media-breakpoint-up(md) {
    .footer-columns {
      .component.col-12 {
        width: auto;

        &.link-list {
          flex-grow: 2;
          flex-shrink: 1;
          flex-basis: 0; // long form rules required to solve IE11 minification issue
        }
      }
    }

    .footer-columns {
      margin-bottom: 2rem;
    }
  }

  @include media-breakpoint-up(lg) {
    .footer-columns {
      .component.col-12.social-links {
        flex-grow: 3;
        flex-shrink: 1;
        flex-basis: 0; // long form rules required to solve IE11 minification issue
        text-align: right;
      }
    }
  }
}

#wrapper > footer.hundred-year-theme {
  background: $hundred-year-midnight-blue;
}
