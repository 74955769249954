// z-index variables
$z-index-mobile-menu: 300;
$z-index-predictive-search-results: 110;

// full width photo banner min heights

$banner-min-height-sm: 300px;
$banner-min-height-md: 400px;
$banner-min-height-lg: 500px;

$banner-tall-min-height-sm: 400px;
$banner-tall-min-height-md: 520px;
$banner-tall-min-height-lg: 640px;

$fa-font-path: '/fonts/font-awesome';

$leaf-radius-sm: 50px;
$leaf-radius-md: 80px;
$leaf-radius: 80px;
