.feature-contact--fixed-placeholder {
  height: 100px;
  width: 100%;

  // The submit container is not fixed from here so there is no need to insert this placeholder
  @include media-breakpoint-up(md) {
    display: none;
  }
}

.feature-contact--submit-container {
  display: flex;

  // Dont display checkmark on small devices because of limited spacing
  @include media-breakpoint-down(md) {
    position: fixed;
    background-color: white;
    width: 100%;
    height: 100px;
    bottom: 0;
    left: 0;
    right: 0;
    justify-content: space-between;
  }

  .feature-contact--inner-submit-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    // Dont display checkmark on small devices because of limited spacing
    @include media-breakpoint-down(md) {
      justify-content: space-between;
      align-items: center;
      padding: 1.5rem;
    }

    .feature-contact--submit-contact-btn {
      font-size: 16px;
      padding: 16px 42px;
    }
  }

  .feature-contact--cancel-btn {
    margin-right: 1rem;
  }
}
