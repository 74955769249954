%photo-listing-list {
  .item-wrapper,
  .article-wrapper {
    flex-direction: row;
  }

  .image-wrapper,
  .article-image {
    width: 100px;
    min-height: 100px;
  }
  .image-wrapper-list {
    width: 104px;
    min-height: 104px;
    max-height: 104px;
    img {
      border-radius: 8px;
    }
  }

  .text-wrapper,
  .article-container {
    padding-left: $middle-margin;
    width: 50%;
  }

  .field-teasertext,
  .field-teasertitle {
    padding: unset;
  }
  .item-title {
    font-size: 16px;
    letter-spacing: 1%;
    color: #132d51;
    font-family: 'Poppins', sans-serif;
    font-weight: $font-weight-medium;
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .item-wrapper-list {
    margin-bottom: 24px;
    padding-top: 24px;
    align-items: center;
  }

  .text-wrapper-list {
    padding: 0 0 0 24px;
  }

  .component-content > ul > li,
  .item {
    padding: 0 14px;
    .item-wrapper-list {
      @include border-basic(top, $border-gray);
    }

    &:first-child {
      .item-wrapper-list {
        border-width: 0;
        padding-top: 0;
      }
      a {
        border-width: 0;
      }
    }
    a {
      @include border-basic(top, $border-gray);

      padding: $photo-item-padding-v 0;
      font-weight: $font-weight-bold;
    }
    .text-wrapper-list {
      a {
        border-width: 0;
        font-size: 14px;
        font-weight: $font-weight-medium;
        padding: 0;
      }
    }
  }

  // tablet sizes
  @include media-breakpoint-up(lg) {
    .image-wrapper,
    .article-image {
      width: 200px;
      min-height: 150px;
      max-height: 200px;
    }
    .text-wrapper-list {
      padding: 24px 0 24px 24px;
      a {
        font-size: 14px;
        font-weight: $font-weight-medium;
        padding: 0;
      }
    }
    .item-title {
      margin-bottom: 24px;
    }
  }

  // Desktop styles
  @include media-breakpoint-up(md) {
    .component-content > ul > li,
    .item {
      background: $page-list-item-bg;
      width: 50%;
      &:nth-child(2) {
        a {
          border-width: 0;
        }
      }
    }

    .snippet &,
    .row-splitter & {
      .component-content > ul > li,
      .item {
        width: 100%;
        min-width: 60%;

        &:nth-child(2) {
          a {
            border-width: 1px;
          }
        }
        .text-wrapper-list {
          a {
            border-width: 0;
            font-size: 14px;
            font-weight: $font-weight-medium;
            padding: 0;
          }
        }
      }
    }
  }

  // Card styles for bg-alt
  &.bg-alt,
  .bg-alt & {
    .component-content > ul > li,
    .item {
      padding: $photo-item-padding-card-v $photo-item-padding-card-h;
      a {
        border: none;
        padding: 0;
      }
    }
  }
}
