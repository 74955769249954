%photo-listing-grid {
  // grid layout styles
  .items {
    margin: (0 - $photo-item-padding-grid-v) (0 - $photo-item-padding-grid-h);
  }

  .component-content > ul > li,
  .item {
    margin: $photo-item-padding-grid-v 0;
    flex-grow: 0;
    flex-shrink: 1;
  }

  .item {
    padding: 0 $photo-item-padding-grid-h;
  }

  &.bg-alt,
  .bg-alt & {
    .component-content > ul,
    .items {
      margin: (0 - $photo-item-padding-grid-card-v) (0 - $photo-item-padding-grid-card-h);
    }

    .component-content > ul > li,
    .item {
      padding: $photo-item-padding-grid-card-v $photo-item-padding-grid-card-h;
      margin: 0;
    }
  }

  .item-wrapper,
  .article-wrapper {
    flex-direction: column;
  }

  .image-wrapper,
  .article-image {
    width: 100%;
    height: 200px;
  }

  .text-wrapper,
  .article-container {
    padding-left: 0;
    width: 100%;
  }

  .field-teasertext,
  .field-teasertitle {
    padding: $photo-item-padding-grid-card-v 0;
  }

  // Desktop styles
  @include media-breakpoint-up(md) {
    .component-content > ul,
    .items {
      flex-direction: row;
      margin: (0 - $photo-item-padding-grid-v) (0 - $photo-item-padding-grid-h);
    }
    .component-content > ul > li,
    .item {
      width: 50%;
      min-width: 40%;
      padding: 0 $photo-item-padding-grid-h;
      @include border-basic(right, $border-gray);

      &:nth-child(2n) {
        border-width: 0;
      }

      &:last-child {
        border-right: none;
      }

      .field-teasertitle {
        padding: $middle-margin 0;
      }
    }

    .item-wrapper,
    .article-wrapper {
      flex-direction: column;
      height: 100%;

      .text-wrapper,
      .article-container {
        padding-left: 0;
        width: 100%;
      }

      .image-wrapper,
      .article-image {
        width: 100%;
        height: 230px;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .component-content > ul > li,
    .item {
      width: 33.33%;
      min-width: 26%;
      background: $page-list-item-bg;
      overflow: hidden;
      &:nth-child(2n) {
        border-width: 1px;
      }
      &:nth-child(3n) {
        border-width: 0;
      }
    }
  }

  &.bg-alt {
    .component-content > ul > li,
    .item {
      border: none;
    }

    .text-wrapper,
    .article-container {
      padding-left: $photo-item-padding-grid-card-h;
      padding-top: 0;
      padding-bottom: 0;
    }

    @include media-breakpoint-up(md) {
      .component-content > ul > li,
      .item {
        width: 50%;
      }
    }

    @include media-breakpoint-up(xl) {
      .component-content > ul > li,
      .item {
        width: 33.33%;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    .collapse-grid-to-list-mobile {
      @include grid-to-list();
    }
  }
}
