.component.top-tasks {
  .component-content {
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: flex-end;

    .pre-heading {
      margin-bottom: 0;
      padding-right: 50px;
      color: $active-blue;
      &.hundred-year-theme {
        color: $hundred-year-midnight-blue;
      }
    }

    ul {
      display: flex;
      flex-flow: row;
      width: 80%;
      margin-bottom: 0;
      padding-left: 0;
      list-style-type: none;
      margin-left: -7.5px;
      margin-right: -7.5px;

      li {
        color: $ract-blue;
        padding: 7.5px;
        flex: 25%;

        a {
          display: block;
          height: 100%;
          background-color: $gray-100;
          &.hundred-year-theme {
            background-color: $hundred-year-midnight-blue;
          }

          padding: 40px 7.5px;
          transition: transform 200ms ease-in-out;
          color: $active-blue;
          transform: scale(1);
          text-decoration: none;

          &:focus,
          &:hover {
            transform: scale(1.05);

            .field-displaytext {
              text-decoration: underline;
            }
          }
        }

        &:first-child {
          > a {
            border-top-left-radius: 40px;
          }
        }
        &:last-child {
          > a {
            border-bottom-right-radius: 40px;
          }
        }

        & .item-wrapper {
          display: flex;
          flex-direction: column;
          align-items: center;
        }

        & .top-tasks-icon {
          font-size: 2.3rem;
          color: $active-blue;
          &.hundred-year-theme {
            color: $hundred-year-gold;
          }

          padding-bottom: 0.4rem;
        }

        & .field-displaytext {
          display: block;
          text-align: center;
          width: 85%;
          line-height: 1.2;
          color: $active-blue;
          &.hundred-year-theme {
            color: $white;
            font-weight: 500;
          }
        }
      }
    }

    @include media-breakpoint-down(xl) {
      flex-wrap: wrap;
      padding-top: 0;
      max-width: 924px;
      justify-content: center;
      margin-left: auto;
      margin-right: auto;
      .pre-heading {
        flex: 100%;
        align-self: center;
        margin-bottom: 25px;
        text-align: center;
        padding-right: 0;
      }

      ul {
        width: 100%;
        > li {
          > a {
            .item-wrapper {
              margin-left: auto;
              margin-right: auto;
              max-width: 75%;
            }
          }

          & .field-displaytext {
            flex: 100%;
          }
        }
      }
    }

    @include media-breakpoint-down(lg) {
      max-width: 824px;
      ul {
        flex-flow: wrap;
      }
    }

    @include media-breakpoint-down(md) {
      max-width: 710px;

      ul {
        li {
          flex: 50%;
          max-width: 50%;
        }
      }
    }

    @include media-breakpoint-down(sm) {
      max-width: none;
      margin-left: -7.5px;
      margin-right: -7.5px;
    }

    // Mobile styles
    @include media-breakpoint-down(xs) {
      ul {
        li {
          > a {
            .item-wrapper {
              max-width: none;
            }
          }
        }
      }
    }
  }
}

// IE11 overrides
@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  .component.top-tasks {
    .component-content {
      ul {
        flex-flow: row;
        li {
          flex: 1 1 25%;
          .item-wrapper {
            display: block;
            width: 100%;
            text-align: center;
            .field-displaytext {
              width: auto;
            }
          }
        }
      }
    }
  }
}
@media screen and (-ms-high-contrast: active) and (max-width: map-get($grid-breakpoints, lg)),
  screen and (-ms-high-contrast: none) and (max-width: map-get($grid-breakpoints, lg)) {
  .component.top-tasks {
    .component-content {
      ul {
        flex-flow: wrap;
        li {
          flex: 1 1 50%;
        }
      }
    }
  }
}
