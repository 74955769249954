/* stylelint-disable */

$header-controls-height: 106px;
$header-controls-padding-v: 10px;

$collapse-menu-padding: $mobile-screen-padding-h;

$nav-item-padding-sm-v: 15px;
$nav-item-padding-sm-h: $collapse-menu-padding;
$nav-item-padding-md-v: 6px;
$nav-item-padding-md-h: 10px;
$nav-item-padding-v: 7px;
$nav-item-padding-h: 12px;

%subtle-link {
  color: $text-basic;
  &:hover,
  &:focus {
    color: $text-dark;
  }
}

@mixin mobile-menu-padding-h {
  padding-left: $nav-item-padding-sm-h;
  padding-right: $nav-item-padding-sm-h;
  @include media-breakpoint-only(lg) {
    padding-left: ($grid-gutter-width / 2) + 3px; // 3px to align with logo
  }
}

header {
  position: relative;
  background: $white;
  z-index: 100;
}

// global header styles
#wrapper > header {
  position: relative;
  margin: 0 auto;

  #header {
    &.hundred-year-theme {
      background-color: $hundred-year-midnight-blue;
    }
  }

  .component {
    position: static;
  }

  .logo-link {
    display: block;
    position: relative;
    z-index: 10;
  }

  .header-icon-links {
    display: flex;
    a,
    button {
      @extend %subtle-link;

      padding: 0 6px 0 5px;
      width: 44px;
      text-align: center;
      line-height: 70px; // ($header-controls-height - (2 * $header-controls-padding-v));
      background: none;
      border: none;
      cursor: pointer;
    }
    &.hundred-year-theme {
      a,
      button {
        color: $white;
        &:hover {
          color: darken($color: $white, $amount: 15%);
        }
      }
    }
  }

  .toggle-menu {
    outline: none;
    box-shadow: none;
    margin-bottom: 14px;
    &[aria-expanded='false'] {
      .icon-close {
        display: none;
      }
    }
    &[aria-expanded='true'] {
      .hamburger-search {
        display: none;
      }
    }
    .hamburger-search {
      fill: $text-basic;
      vertical-align: middle;
      height: 24px;
      display: unset;
      &.hundred-year-theme {
        fill: $white;
        &:hover {
          fill: darken($color: $white, $amount: 15%);
        }
      }
    }
  }

  .utility-links {
    a {
      @extend %subtle-link;

      padding: 0;

      &:focus,
      &:hover {
        border-color: $text-dark;
      }
    }
  }

  .member-hub .btn {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    border-radius: 28px 0 28px 0;
    min-width: 0;
  }

  .member-hub {
    &.hundred-year-theme {
      a {
        background-color: $hundred-year-gold;
        border-color: $hundred-year-gold;
        &:hover {
          background-color: $hundred-year-gold-hover;
          border-color: $hundred-year-gold-hover;
        }
      }
    }
  }

  // Global megamenu styles
  .megamenu {
    .submenu-link {
      text-decoration: none;
      appearance: none;

      &:hover,
      &:focus {
        color: $active-blue;
        text-decoration: none;
      }
    }
  }
}

// Mobile header styles
@include media-breakpoint-down(md) {
  #wrapper > header {
    .row {
      display: block;
    }
    .menu-collapse {
      // position the menu
      position: fixed;
      top: $header-controls-height;
      bottom: 0;
      left: 100%;
      width: 100%;
      visibility: hidden;
      transition-timing-function: ease-in-out;
      transition-duration: 0.3s;
      transition-property: left, visibility;

      // style the menu contents
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background-color: $white;
      z-index: $z-index-mobile-menu;
      overflow-y: auto;
      overflow-x: hidden;
      overscroll-behavior: none;
      &.sub-menu-active {
        overflow-y: hidden;
      }
    }
    .menu-collapse.active {
      left: 0;
      visibility: visible;
    }

    .header-controls {
      display: flex;
      justify-content: space-between;
      // minus 2px on the left to account for drop shadow in the logo image
      padding: $header-controls-padding-v 0;
      height: $header-controls-height;

      .logo-link .logo {
        width: auto;
        margin-top: 15.5px;
        height: 60px; //($header-controls-height - (2.5 * $header-controls-padding-v));
      }
    }

    .header-content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .menu-list > li {
      border-top: 1px solid $border-gray;
      & > button {
        border: none;
        @include make-container();
        @include make-container-max-widths();
        @include mobile-menu-padding-h;
      }
    }

    .header-utilities {
      flex-grow: 1;
      flex-shrink: 1;
      display: flex;
      flex-direction: column;
      background: $gray-50;
      padding: $collapse-menu-padding $collapse-menu-padding - ($grid-gutter-width / 2);
      ul {
        padding-left: 0;
      }
    }

    .site-search {
      padding-bottom: $collapse-menu-padding;
      order: -1;
      @include make-container();
      @include make-container-max-widths();
    }

    .utility-links {
      @include make-container();
      @include make-container-max-widths();

      ul {
        margin-bottom: 1rem;
        margin-top: -0.3rem;
        > li {
          margin-top: 0.3rem;
        }
      }
    }

    .cta-link {
      @include make-container();
      @include make-container-max-widths();
    }

    // Submenu mobile styles
    $back-button-width: 40px;
    .megamenu-wrapper {
      display: block;
      position: absolute;
      z-index: $z-index-mobile-menu;
      background: $white;
      width: 100%;
      top: 0;
      transform: translateX(100%);
      transition: transform 0.3s ease-in-out;
      max-height: 100%;
      overflow-y: hidden;
      display: flex;

      ul {
        list-style-type: none;
        padding-top: 2em;
        padding-bottom: 2em;

        li {
          padding-bottom: 1em;
          &:last-child {
            padding-bottom: 0;
          }
        }
      }

      &::after {
        display: none;
      }

      &.show {
        transform: translateX(0%);
        overflow-y: auto;
      }
      .main-content-overlay {
        display: none;
      }
    }
    .megamenu {
      display: flex;
      flex-direction: column;

      &.container {
        padding: 0;
        max-width: 100%;
        flex-grow: 1;
        + .icon-close {
          display: none;
        }
      }
      & a {
        text-decoration: none;
        color: $text-dark;
        &:hover,
        &:focus {
          text-decoration: underline;
        }
      }
      .megamenu-heading {
        display: block;
        text-align: center;
        padding: 15px $back-button-width 15px 0;
        border-bottom: 1px solid $border-gray;
        border-top: 1px solid $border-gray;

        & .close-submenu {
          all: inherit;
          border: none;
          float: left;
          min-width: $back-button-width;
          padding: 0;
          cursor: pointer;
        }
      }
      .menu-column {
        &:nth-of-type(3) {
          .submenu-subtitle {
            display: none;
          }
        }
        &:last-of-type {
          flex-grow: 1;
        }
        ul {
          a {
            padding: 0;
          }
        }
        .submenu-subtitle {
          margin: 32px auto 0 auto;
          color: $ract-blue;
        }
        &.bg-alt {
          ul {
            padding-top: 1em;
            padding-bottom: 1em;
            + .view-all-link {
              margin-bottom: 2em;
            }
          }
          .view-all-link {
            color: $active-blue;
            display: block;
          }
          .view-all-link:first-child {
            display: none;
          }
        }
      }
    }
  }
}
// xs devices only - no container max widths
@include media-breakpoint-down(xs) {
  #wrapper > header .header-controls {
    padding: $header-controls-padding-v 0 $header-controls-padding-v
      ($collapse-menu-padding - ($grid-gutter-width / 2) - 2px);
  }
}

@include media-breakpoint-up(lg) {
  #wrapper > header {
    .logo-link {
      .logo,
      img {
        height: 100px;
        width: auto;
      }
    }
  }
}
@include media-breakpoint-up(lg) {
  #wrapper > header {
    padding: $middle-margin 0;

    .component-content {
      display: flex;
    }

    .menu-collapse {
      display: flex;
      justify-content: space-between;
    }

    .header-controls {
      flex: 0 0 auto;
    }

    .header-icon-links {
      display: none;
    }

    .logo-link {
      margin-bottom: $nav-item-padding-v;
    }

    .header-content {
      flex-grow: 1;
      flex-shrink: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .header-utilities {
      order: -1;
      flex-grow: 0;
      flex-shrink: 1;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      // fix a rendering issue in experience editor
      .component {
        flex: 0 0 auto;
      }
    }

    .primary-nav {
      flex: 0 0 auto;
    }

    .utility-links {
      &.hundred-year-theme {
        a {
          color: $white;
          &:hover {
            color: darken($color: $white, $amount: 15%);
            border-color: darken($color: $white, $amount: 15%);
          }
        }
      }
      ul {
        @include list-inline();
        > li {
          margin: 0 0.5rem;
        }
      }
    }

    // Secondary nav - Expanding menu
    .megamenu-wrapper {
      position: absolute;
      width: 100%;
      left: 0;
      display: none;
      background: $white;
      border-top: 1px solid $border-gray;

      &.show {
        transform: translateY(20px);
        display: block;
        z-index: $z-index-mobile-menu;
        .main-content-overlay {
          display: fixed;
        }
      }
      .icon-close {
        position: absolute;
        right: 10px; //1.2%;
        top: 10px; //5%;
        font-size: 20px;
        color: $text-basic;
        cursor: pointer;
      }
      &::after {
        content: '';
        height: 100%;
        position: absolute;
        z-index: -1;
        top: 0;
        bottom: 0;
        right: 0;
        background: $gray-100;
        @include media-breakpoint-up(lg) {
          width: calc(50vw - (930px / 2));
        }
        @include media-breakpoint-up(xl) {
          width: calc(50vw - (1110px / 2));
        }
        @include media-breakpoint-up(xxl) {
          width: calc(50vw - (1250px / 2));
        }
      }

      .megamenu {
        display: flex;
        position: relative;
        ul {
          padding-left: 0;
          list-style: none;
        }
        .megamenu-heading {
          display: none;
        }
        .button-wrapper {
          margin-top: 2em;
          a {
            @include btn-sm();

            text-decoration: none;
          }
        }
        .menu-column {
          padding: 20px 0;
          text-align: left;
          &:nth-child(2) {
            ul {
              display: flex;
              flex-wrap: wrap;
              padding-top: 30px; // put into var

              li {
                flex-grow: 0;
                flex-shrink: 0;
                flex-basis: auto;
                width: 50%;
                padding-bottom: 0.6rem; // put into var
              }
            }
          }
          &:last-of-type {
            width: 20%;
            padding-left: 30px; // use gutterwidth var

            ul {
              padding-top: 30px;
              li {
                padding-bottom: 0.6rem; // put into var
              }
            }
          }

          & h2 {
            color: $ract-blue;
            padding-right: 1em;
          }
          & .submenu-subtitle {
            color: $ract-blue;
            margin-bottom: 0;

            // if the optional subtitle is present, remove the top padding from the ul
            + ul {
              padding-top: 0;
            }
          }
          & .submenu-description {
            width: 70%;
            color: $gray-800;
          }
          & .view-all-link {
            display: block;
            margin-top: 2rem;
          }
          & .button-wrapper {
            margin-top: 4rem;
          }
        }
      }
    }
    .main-content-overlay {
      background: rgba(0, 0, 0, 0.7);
      opacity: 0.9;
      position: absolute;
      height: 1500vh;
      inset: 49px 0 0 0;
      margin-left: -100%;
      margin-right: -100%;
      cursor: default;
    }
  }
}

// primary navigation styles
.primary-nav {
  .menu-content {
    display: none;
  }

  .menu-list {
    margin: 0;
    list-style: none;
    padding-left: 0;
  }

  .menu-item-link {
    display: flex;
    color: $text-dark;
    font-family: $font-family-secondary;
    text-decoration: none !important;
    justify-content: space-between;
    align-items: center;
    appearance: none;
    background-color: transparent;
    border: none;
    outline: none;

    &:hover,
    &:focus {
      text-decoration: none;
      color: $active-blue;
    }
  }

  @include media-breakpoint-down(md) {
    .menu-item-link {
      border-top: 1px solid $border-gray;
    }
    .menu-list {
      border-bottom: 1px solid $border-gray;
    }
  }

  @include media-breakpoint-down(breakpoint-next(md)) {
    .menu-item-link {
      font-size: $font-size-sm;
    }
  }

  @include media-breakpoint-up(lg) {
    .menu-item-link {
      padding: $nav-item-padding-md-v $nav-item-padding-md-h;
    }

    .menu-item-underline {
      height: 3px;
      position: relative;
      top: 21px;
    }

    .menu-list {
      text-align: right;
      @include list-inline();
    }

    .menu-toggle.expanded {
      + .menu-item-underline {
        background: #2376d8;
      }
    }
  }

  @include media-breakpoint-up(xl) {
    .menu-item-link {
      padding: $nav-item-padding-v $nav-item-padding-h;
    }
  }
}

// Search box and button styles
.site-search {
  .search-input-wrapper {
    background-color: $white;
    border: 1px solid $border-gray;
    height: 46px;
    border-radius: 50rem;
    vertical-align: middle;
    padding: 0 0.4rem 0 0.75rem;
    display: flex;

    @include media-breakpoint-up(lg) {
      background-color: $gray-50;
      border: 1px solid $gray-50;
      margin: 0 1rem;
    }

    &.hundred-year-theme {
      background-color: $white;
    }
  }
  .search-input {
    //height: $input-height;
    padding: 0; //$input-padding-y $input-padding-x;
    font-size: $font-size-sm-ish;
    font-weight: $input-font-weight;
    line-height: $input-line-height;
    color: $input-color;
    background-color: transparent;
    background-clip: padding-box;
    border: none;
    color: $text-black;
    flex-grow: 1;
    flex-shrink: 1;
    width: 178px;
    line-height: normal;
  }

  .search-button {
    background: transparent;
    border: none;
    padding: 0 0.4rem;
    cursor: pointer;
    vertical-align: middle;
    color: $text-dark;
    .far {
      width: 1.2em;
    }
    &:hover,
    &:focus {
      color: $text-black;
    }
  }
}
#wrapper {
  overflow-x: hidden;
  overflow-y: hidden;
}
//
//
//
//
/////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Rebrand 2021 overrides
// Mobile
////////////////////////////////////////////////////////////////////////////////////////////////////////////
#wrapper > header {
  &::before {
    position: absolute;
    z-index: 5;
    width: calc(100% - #{$gutter});
    display: block;
    content: '';
    bottom: 0;
    left: 0;
    height: 30px;
    background-color: $active-blue;
  }

  &.hundred-year-theme {
    &::before {
      background-color: $hundred-year-midnight-blue;
    }
  }

  .header-controls {
    max-width: 540px;
    margin: 0 auto;
    padding-left: 25px;
    padding-right: 0;
  }

  .primary-nav {
    .megamenu {
      .menu-column {
        .submenu-link {
          padding-left: 42px;
          &.hundred-year-theme {
            color: $hundred-year-midnight-blue;
          }
        }
        .view-all-link,
        .submenu-link {
          display: block;
          position: relative;
          text-decoration: none;
          .icon {
            display: block;
            position: absolute;
            left: -7.5px;
            top: 3px;
            text-align: center;
            width: 30px;
            height: 16px;
            color: $ract-blue;
            &.hundred-year-theme {
              color: $hundred-year-gold;
            }
          }
          &:hover,
          &:focus {
            > span:last-child {
              text-decoration: underline;
            }
          }
        }

        .view-all-link {
          appearance: none;
          color: $ract-blue;
          font-weight: $font-weight-bold;
          &.hundred-year-theme {
            color: $hundred-year-gold;
          }

          .icon {
            position: absolute;
            width: 25px;
            text-align: left;
            color: inherit;
          }

          &:hover,
          &:focus {
            text-decoration: none;
          }
        }
      }
    }

    .menu-column {
      .view-all-link,
      .submenu-subtitle,
      ul {
        max-width: 530px;
        margin-left: auto;
        margin-right: auto;
        padding-left: 20px;
        padding-right: 0;
      }
      ul {
        > li {
          padding-bottom: 25px;
        }
      }
      &:last-child {
        > .submenu-subtitle {
          margin-left: auto;
          margin-right: auto;
        }
        > ul {
          padding-bottom: 0;
          > li {
            padding-left: 5px;
          }
        }
      }
    }
  }
}
@media (min-width: 768px) {
  #wrapper > header {
    .logo-container {
      padding-left: 0;
    }
    .header-controls {
      max-width: 720px;
    }
  }
}
@include media-breakpoint-up(md) {
  #wrapper > header {
    .primary-nav {
      .menu-column {
        .view-all-link,
        .submenu-subtitle,
        ul {
          max-width: 706px;
        }
      }
      .megamenu-wrapper {
        .icon-close {
          right: 0;
        }
      }
    }
  }
}

@include media-breakpoint-down(md) {
  // Mobile
  #wrapper > header {
    padding-right: $gutter;
    .primary-nav {
      padding-right: $gutter;
      > .menu-list {
        border-bottom: none;
        > li {
          .menu-item-link {
            color: $ract-blue;
            padding: 17px 16px 17px 25px;
            &.hundred-year-theme {
              color: $dark-midnight;
              svg {
                color: $hundred-year-gold;
              }
            }
          }
        }
        .submenu-subtitle {
          display: none;
        }
      }
      .megamenu-wrapper {
        width: 100% !important;
        overflow-x: hidden;
      }
      .megamenu {
        padding-right: $gutter;
        .megamenu-heading {
          padding-left: 10px;

          a,
          .close-submenu {
            color: $ract-blue;
            &.hundred-year-theme {
              color: $hundred-year-midnight-blue;
            }
            font-family: $font-family-secondary;
            appearance: none;
          }

          .close-submenu {
            > span {
              text-indent: 10px;
              &::before {
                color: $ract-blue;
              }
            }
          }
        }

        .menu-column {
          &.bg-alt {
            &::before {
              position: absolute;
              top: 0;
              right: -25px;
              width: 75px;
              background-color: $gray-50;
              content: '';
              height: 100%;
            }
          }
          &:last-child {
            position: relative;
            padding-left: $gutter;
            padding-right: 0;
            .submenu-subtitle {
              display: block;
              color: $ract-blue;
              margin-top: 25px;
              margin-bottom: 25px;
              padding-left: 6px;
            }
            > ul {
              position: relative;
              padding-top: 30px;
              padding-left: 7.5px;
              padding-right: 0;
              &::before {
                position: absolute;
                top: 0;
                left: -200px;
                width: 200vw;
                background-color: #fff;
                content: '';
                height: 1px;
              }
              > li {
                > a {
                  padding-left: 20px;
                  .icon {
                    width: 25px;
                    text-align: left;
                  }
                }
              }
            }
            .view-all-link {
              padding-left: 32px;
              .icon {
                padding-left: 12px;
              }
            }
          }
        }
      }
    }

    .site-search .search-input-wrapper {
      border: none;
    }

    .header-utilities {
      border-top: 1px solid #dddfe4;
    }
  }
}

// Desktop view
@include media-breakpoint-up(lg) {
  #wrapper > header {
    padding: 27px 0;
    margin-right: $gutter-md;

    &::before {
      display: block;
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 50px;
      background-color: $active-blue;
      z-index: 10;
    }

    &.hundred-year-theme {
      background-color: $hundred-year-midnight-blue;
      padding-top: 0;
      &::before {
        background-color: $hundred-year-midnight-blue;
      }
    }

    > #header {
      max-width: 785px;
      margin-left: auto;
      margin-right: auto;
      &.hundred-year-theme {
        padding-top: 27px;
      }
      > .row {
        margin: 0 -7px;
      }
    }

    .logo-container {
      .logo-link {
        position: relative;
        z-index: 10;
        margin-top: -3px;
        .logo {
          height: 88px;
          margin-left: 0;
        }
      }
    }
    .ract-menu {
      margin-bottom: 55.5px;
    }

    .header-controls {
      position: absolute;
      padding-left: 0;
      margin: 0;

      #hundred-year-logo {
        position: absolute;
        left: -50px;
      }
    }

    .header-utilities {
      &.hundred-year-theme {
        transform: translateX(10%);
      }
    }

    .primary-nav {
      position: absolute;
      z-index: 20;
      color: #fff;
      width: 100%;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      height: 50px;
      max-width: 796px;

      > .menu-list {
        display: flex;
        justify-content: flex-end;
        max-height: 50px;
        padding-right: 0;

        > li {
          .menu-item-link {
            transition: 0.2s background-color;
            padding: 16px 13.7px 15.5px;
            color: #fff;
            max-height: 50px;
            white-space: nowrap;

            &::after {
              display: none;
            }

            &:hover,
            &:focus {
              background-color: rgba(#000, 0.1);
            }

            &.expanded {
              background-color: $ract-blue;
              &.hundred-year-theme {
                background-color: $hundred-year-midnight-blue;
              }
            }

            + .menu-item-underline {
              display: none;
            }
          }
        }
      }
    }

    .megamenu-wrapper {
      top: 49px;
      border: none;
      width: 100vw;
      width: calc(100vw - 65px); // This overidden by JS to fix Safari bug
      left: 50%;
      &.show {
        transform: translate(-50%, 0);
      }
      &::before {
        position: absolute;
        z-index: 10;
        width: 100%;
        display: block;
        content: '';
        top: 0;
        left: 0;
        height: 50px;
        background-color: $ract-blue;
      }

      &::after {
        position: absolute;
        z-index: 10;
        width: 100vw;
        display: block;
        content: '';
        top: 100%;
        height: 10px;
      }

      &.hundred-year-theme {
        &::before {
          background-color: $dark-midnight;
        }
        &::after {
          background-color: $hundred-year-gold;
        }
      }

      .megamenu {
        position: relative;
        z-index: 20;
        margin: 0 auto;
        padding-left: 56px;
        max-width: 938px;
        .menu-column {
          padding: 0 $gutter 20px 5px;
          flex: 65%;
          max-width: 65%;
          z-index: 100;

          &.bg-alt {
            background-color: transparent;
          }

          > ul {
            margin-top: 30px;
            > li {
              margin-bottom: 8px;
            }
          }

          .submenu-subtitle {
            color: #fff;
            line-height: 50px;
          }

          &:not(:last-child) {
            .view-all-link,
            .submenu-link {
              display: flex;
              align-items: center;
              padding-left: 0;
              padding-right: 30px;

              > span:last-child {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
          }

          &:last-child {
            position: relative;
            flex: 35%;
            max-width: 35%;
            border-left: 10px solid $gray-100;
            padding-bottom: 65px;
            padding-right: 35px;
            padding-left: 40px;
            &::after {
              position: absolute;
              content: '';
              top: 0;
              left: -12px;
              height: 50px;
              width: 15px;
              background-color: $ract-blue;
            }
            > .submenu-subtitle {
              padding-left: 0;
            }
            > ul {
              padding-left: 0;
              > li {
                padding-left: 0;
                margin-left: -7.5px;
              }
            }

            .view-all-link,
            .submenu-link {
              padding-left: 30px;
            }
            .view-all-link {
              max-width: none;
              position: absolute;
              bottom: 38px;
              padding-left: 25px;
              margin-left: -7.5px;

              .icon {
                margin-left: 0;
                left: 3px;
                text-align: left;
              }
            }
          }

          &.hundred-year-theme {
            &:last-child {
              &::after {
                background-color: $dark-midnight;
              }
            }
          }
        }
      }

      .icon-close {
        z-index: 100;
        padding: 10px 16px;
        width: 50px;
        height: 50px;
        top: 0;
        right: -50px;
        left: auto;
        background-color: $ract-blue;
        transition: 0.2s background-color;
        text-align: center;
        &::before {
          position: absolute;
          font-size: 30px;
          color: #fff;
          width: 100%;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
        }
        &:hover,
        &:focus {
          background-color: $active-blue;
        }
        &.hundred-year-theme {
          background-color: $hundred-year-gold;
          &:hover,
          &:focus {
            background-color: $hundred-year-gold-hover;
          }
        }
      }
    }

    .site-search .search-input-wrapper {
      height: 2.5rem;
    }
  }
}

@include media-breakpoint-up(xl) {
  #wrapper > header {
    > #header {
      max-width: 1008px;
    }

    .header-controls {
      padding-left: 0;
    }

    .header-utilities {
      &.hundred-year-theme {
        transform: translateX(5%);
      }
    }

    .primary-nav {
      max-width: 1008px;

      > .menu-list {
        > li {
          .menu-item-link {
            padding: 15px 11px 15px;

            &::after {
              display: inline-block;
            }
          }
        }
      }
      .megamenu-wrapper {
        .megamenu {
          max-width: 1140px;
          padding-left: 50px;

          .menu-column {
            flex: 65%;
            max-width: 65%;
            ul > li {
              padding-bottom: 0.75rem;
            }
            &:not(:last-child) {
              .view-all-link,
              .submenu-link {
                padding-left: 40px;
              }
            }
            &:last-child {
              flex: 35%;
              max-width: 35%;
              .view-all-link {
                bottom: 40px;
              }
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  #wrapper > header {
    .header-controls {
      .logo-link .logo {
        width: auto;
        margin-top: 4px;
        height: 88px;
      }
    }
  }
}

@include media-breakpoint-up(xxl) {
  #wrapper > header {
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-left: $gutter-xxl;
    margin-right: $gutter-xxl;
    &.hundred-year-theme {
      background-color: transparent;
    }

    &::before {
      display: none;
    }

    > #header {
      max-width: map-get($grid-breakpoints, xxl);
    }

    .ract-menu {
      margin-bottom: 0;
      padding: 0 7px;
    }

    .header-controls {
      padding-left: $gutter-xxl;
    }

    .header-content {
      position: relative;
    }

    .header-utilities {
      &.hundred-year-theme {
        margin-right: 100px;
      }
    }

    .primary-nav {
      width: 100%;
      bottom: 0;
      left: 0;
      transform: translateX(0);
      max-width: none;
      position: relative;
      right: auto;
      margin-top: 32px;
      background-color: $active-blue;
      z-index: 2;

      &.hundred-year-theme {
        background-color: $hundred-year-midnight-blue;
        transform: translateX(0);
      }

      .megamenu-wrapper {
        left: 0;
        width: 100% !important;
        transform: translate(0, 0);
        &::before {
          height: 75px;
        }
        &::after {
          width: 100%;
        }

        .icon-close {
          width: 75px;
          height: 75px;
          right: 0;
          &::before {
            font-size: 44px;
          }
        }

        .megamenu {
          max-width: none;
          padding-left: $gutter-xxl;
          .submenu-subtitle {
            margin-top: 10px;
            padding-left: 0;
            margin-left: 0;
          }
          .menu-column {
            ul {
              margin-top: 40px;
              margin-bottom: 20px;
              margin-left: 0;
              padding-left: 0;
              > li {
                padding-bottom: 1rem;
              }
            }
            .view-all-link,
            .submenu-link {
              padding-left: 28px;
            }
            &:last-child {
              position: relative;
              padding-bottom: 80px;
              ul {
                margin-bottom: 12.5px;
              }
              &::after {
                height: 75px;
              }
              .view-all-link {
                bottom: 56px;
                padding-left: 23px;
                margin-left: 24px;
              }
              .submenu-subtitle,
              ul {
                padding-left: 25px;
              }
            }
          }
        }
      }

      > .menu-list {
        margin-right: 75px;
        > li {
          .menu-item-link {
            padding: 13px 18px 12px;
          }
        }
      }
    }

    .logo-container {
      .logo-link {
        margin-top: -3.5px;
      }
    }
  }
}
