@use 'sass:math';

.component.feature-item {
  overflow: hidden;
  box-sizing: border-box;

  .feature-item-wrapper {
    display: flex;
    padding: $promo-feature-item-padding;
    background: $gray-100;
    border: none;
    border-top-left-radius: $leaf-radius-sm;
    border-bottom-right-radius: $leaf-radius-sm;
    overflow: hidden;

    @include media-breakpoint-up(md) {
      border-top-left-radius: $leaf-radius-md;
      border-bottom-right-radius: $leaf-radius-md;
    }

    &.hidden-background {
      background: $white;
      border-radius: 0;
      padding: 0;
    }
  }

  // make the box white when on a gray background
  &.bg-alt .feature-item-wrapper,
  .bg-alt & .feature-item-wrapper {
    background: $body-bg;
  }

  .field-feature-image {
    overflow: hidden;
    flex-grow: 1;
    flex-shrink: 1;
    width: 50%;
    border-top-left-radius: math.div($leaf-radius-sm, 1.5);

    img {
      width: auto;
      height: auto;
    }

    &.hidden-background {
      border-radius: 0;
    }

    &.video-thumbnail {
      height: 100%;
    }

    // Crop image sides for tablet devices
    @include media-breakpoint-down(breakpoint-next(md)) {
      img {
        width: 100%;
      }
    }

    @include media-breakpoint-up(md) {
      border-top-left-radius: math.div($leaf-radius-md, 1.5);
      position: relative;

      img {
        height: 100%;
        left: 50% !important;
        transform: translate(-50%, 0);
        max-width: none;
        position: absolute;
        bottom: 0;
        top: 0;
      }
    }

    @supports (object-fit: cover) {
      img {
        position: relative;
        object-fit: cover;
        object-position: center center;
      }
    }

    // reduce the width of the image on large devices to maintain a more consistent aspect ratio accross the various screen sizes
    @include media-breakpoint-up(xl) {
      width: 35%;
    }
  }

  .feature-section {
    margin-left: 40px;
    width: 50%;
    flex-grow: 1;
    flex-shrink: 1;
    margin-top: 20px;
    margin-bottom: 20px;

    .field-feature-title {
      color: $active-blue;
      margin: 0 0 24px 0;
    }

    .field-feature-description {
      ul {
        padding-left: 20px;
      }
    }

    &.hidden-background {
      margin-bottom: 40px;
      margin-top: 50px;
      flex-basis: 45%;
    }

    @include media-breakpoint-down(sm) {
      margin-top: 30px;
      &.hidden-background {
        margin-top: 30px;
      }
    }

    @include media-breakpoint-up(xl) {
      margin-left: 60px;
    }
  }

  &.feature-item-image-right {
    .field-feature-image {
      @include media-breakpoint-up(md) {
        order: 1;
        border-top-left-radius: 0;
        border-bottom-right-radius: math.div($leaf-radius, 2);

        &.hidden-background {
          border-bottom-right-radius: 0;
        }
      }
    }

    .feature-section {
      @include media-breakpoint-up(md) {
        margin-right: 1.5rem;
        margin-left: 20px;

        &.hidden-background {
          margin-right: 60px;
          margin-left: 10px;
        }
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .component.feature-item.col-12 {
    > .component-content .feature-item-wrapper {
      flex-direction: column;
    }

    .feature-section {
      margin-left: 0;
      width: 100%;
    }

    .field-feature-image {
      width: 100%;
    }

    &.feature-item-image-right {
      > .component-content .feature-item-wrapper {
        flex-direction: column-reverse;
      }
    }
  }
}

.component-content {
  &.hidden-background {
    max-width: 100%;

    @include media-breakpoint-up(lg) {
      max-width: 1280px;
    }
  }
}
