@mixin figure-column-layout() {
  display: flex;
  figure {
    padding: 0 8px;
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
  }
  .figure-image-wrapper {
    width: 100%;
    position: relative;
    overflow: hidden;
  }
  // !important required to override inline values injected in Experience Editor
  img {
    width: auto !important;
    height: 100% !important;
    max-width: none;
    min-width: 100%;
    left: 50%;
    transform: translate(-50%, 0);
    position: absolute;
    bottom: 0;
    top: 0;
  }
}

// Only the essential styles required for simple rich text renderings
// such as accordion content or feature item description
@mixin rich-text-minimal() {
  //
  // Headings
  //

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6 {
    margin-bottom: $headings-margin-bottom;

    // remove top margin when heading is the first element
    &:first-child {
      margin-top: 0;
    }
  }

  h1,
  .h1 {
    color: $active-blue;
  }

  h2,
  .h2 {
    margin-top: 3rem;
  }

  h3,
  .h3 {
    margin-top: 2rem;
  }

  h4,
  .h4 {
    margin-top: 1.5rem;
  }

  h5,
  .h5 {
    margin-top: 1.5rem;
  }

  h6,
  .h6 {
    margin-top: 1.5rem;
  }

  // remove the bottom margin of the last element in any rich text area
  > p,
  > ul,
  > ol {
    &:last-child {
      margin-bottom: 0;
    }
  }

  //
  // links
  //
  a:not(.btn) {
    color: $link-color;
    text-decoration: $link-decoration;
    background-color: transparent; // Remove the gray background on active links in IE 10.

    &:hover {
      color: $link-hover-color;
      text-decoration: $link-hover-decoration;
    }
  }

  li > a {
    text-decoration: none;
  }

  //
  // Emphasis
  //
  strong,
  .strong {
    font-weight: $font-weight-bold;
  }

  mark,
  .mark {
    padding: $mark-padding;
    background-color: $mark-bg;
  }

  //
  // Horizontal rules
  //

  hr {
    margin-top: $hr-margin-y;
    margin-bottom: $hr-margin-y;
    border: 0;
    border-top: $hr-border-width solid $hr-border-color;
  }

  //
  // Lists
  //

  // Ordered list styles
  ol:not(.fa-ul) {
    padding-left: 2em;
    list-style-type: decimal;
    ol {
      list-style-type: lower-alpha;
      ol {
        list-style-type: lower-roman;
      }
    }
  }

  // Unordered lists
  ul:not(.fa-ul) {
    padding-left: 2em;
    list-style-type: disc;
    ul {
      list-style-type: circle;
      ul {
        list-style-type: square;
      }
    }
  }

  .list-unstyled {
    @include list-unstyled();
  }

  // Inline turns list items into inline-block
  .list-inline {
    @include list-unstyled();
  }

  .list-inline > li,
  .list-inline-item {
    display: inline-block;

    &:not(:last-child) {
      margin-right: $list-inline-padding;
    }
  }

  // button list
  .button-list {
    @include list-unstyled();
    > li {
      display: inline-block;

      &:not(:last-child) {
        margin-right: $list-inline-padding;
      }
    }
    .btn {
      margin-bottom: 10px;
    }
  }

  //
  // Definition lists
  //
  dd {
    @include clearfix();

    margin-bottom: 0.375em;
    &:last-child {
      margin-bottom: 0;
    }
  }
  $dt-width: 12em;
  @include media-breakpoint-up(sm) {
    dt {
      float: left;
      width: $dt-width;
      clear: left;
      text-align: right;
      @include text-truncate();
    }
    dd {
      margin-left: ($dt-width + 1em);
    }
  }
}

// All rich text styles required for body copy text
@mixin rich-text() {
  @include rich-text-minimal();

  $side-overhang: (map-get($container-max-widths, xl) - map-get($container-max-widths-narrow, xl)) / 2;

  // Block Quotes
  blockquote,
  .blockquote {
    margin-top: map-get($spacers, 5);
    margin-bottom: map-get($spacers, 5);
    font-family: $headings-font-family;
    font-weight: $headings-font-weight;
    line-height: $headings-line-height;
    font-size: 20px;
    text-align: center;
    color: $gray-800;

    @include media-breakpoint-up(sm) {
      font-size: 26px;
    }

    @include media-breakpoint-up(lg) {
      font-size: 38px;
      margin-top: map-get($spacers, 6);
      margin-bottom: map-get($spacers, 6);
    }

    @include media-breakpoint-up(xl) {
      margin-left: (0 - $side-overhang);
      margin-right: (0 - $side-overhang);
    }
  }

  blockquote > footer,
  .blockquote-footer {
    display: block;
    font-size: $font-size-sm;
    color: $gray-400;

    &::before {
      content: '\2014\00A0'; // em dash, nbsp
    }
  }

  // Limit the width and center align images
  img {
    max-width: 100%;
    height: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  // Media object BS overrides
  img.media-object {
    max-width: initial;
  }

  // extend media object
  a > .media,
  a.media {
    display: block;
  }

  // add a focus effect to images to make up for the missing dotted outline in IE
  a:focus {
    img {
      opacity: (0.8);
    }
  }
  a:hover {
    img {
      opacity: (0.9);
    }
  }

  // fine print for T&Cs etc
  .fine-print {
    font-size: $font-size-xs;
  }

  // Images in body copy
  figure,
  .image-center,
  .image-left,
  .image-right {
    margin-bottom: 1rem;
    padding-top: 0.375em;
  }

  @include media-breakpoint-up(sm) {
    .figure-left,
    .image-left {
      float: left;
      clear: left;
      max-width: 50%;
      //padding-right: 0.5rem;
      margin-right: 1.5rem;
      margin-bottom: 1rem;
    }

    .figure-right,
    .image-right {
      float: right;
      clear: right;
      max-width: 50%;
      //padding-left: 0.5rem;
      margin-left: 1.5rem;
      margin-bottom: 1rem;
    }

    .figure-left,
    .image-left,
    .figure-right,
    .image-right {
      + h2,
      + h3,
      + h4 {
        margin-top: 0;
      }
    }

    .three-figure-column {
      @include media-breakpoint-up(lg) {
        @include figure-column-layout();

        figure {
          width: 33.333%;
          box-sizing: content-box;
        }

        .figure-image-wrapper {
          height: 230px; // Maintains 16:9 aspect ratio
        }

        figcaption {
          font-weight: $font-weight-normal;
          margin-top: 0.5em;
        }
      }
      @include media-breakpoint-up(xl) {
        .figure-image-wrapper {
          height: 290px;
        }
      }
    }

    .two-figure-column {
      @include media-breakpoint-up(lg) {
        @include figure-column-layout();

        figure {
          width: 50%;
          box-sizing: content-box;
        }

        .figure-image-wrapper {
          height: 370px; // Maintains 16:9 aspect ratio
        }

        figcaption {
          font-weight: $font-weight-normal;
          margin-top: 0.5em;
        }
      }
    }

    // Portrait image snippets: images must be set at a 2:3 ratio with a minimum width of 620px (x 930px)
    .two-figure-column.portrait {
      @include media-breakpoint-up(md) {
        @include figure-column-layout();

        figure {
          width: 50%;
          box-sizing: content-box;
        }

        .figure-image-wrapper {
          height: 505.5px;
        }
      }
      @include media-breakpoint-up(lg) {
        .figure-image-wrapper {
          height: 685.5px;
        }
      }
      @include media-breakpoint-up(xl) {
        .figure-image-wrapper {
          height: 820.5px;
        }
      }
      @include media-breakpoint-up(xxl) {
        .figure-image-wrapper {
          height: 925.5px;
        }
      }
    }

    .three-figure-column.portrait {
      @include media-breakpoint-up(md) {
        @include figure-column-layout();

        figure {
          width: 33.333%;
          box-sizing: content-box;
        }

        .figure-image-wrapper {
          height: 328.5px;
        }
      }
      @include media-breakpoint-up(lg) {
        .figure-image-wrapper {
          height: 448.5px;
        }
      }
      @include media-breakpoint-up(xl) {
        .figure-image-wrapper {
          height: 538.5px;
        }
      }
      @include media-breakpoint-up(xxl) {
        .figure-image-wrapper {
          height: 609px;
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .figure-left,
    .image-left {
      max-width: 40%;
    }

    .figure-right,
    .image-right {
      max-width: 40%;
    }
  }

  caption,
  figcaption {
    font-size: 0.75em;
    font-weight: $font-weight-normal;
    text-align: center;
    margin-top: 4px;
  }
}
