.mobile-apps-container {
  max-width: map-get($container-max-widths, lg);
  margin: 0 auto;

  p {
    text-align: center;
  }

  .store-buttons {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;

    a {
      margin-bottom: 20px;
    }

    svg {
      height: 60px;
    }
  }
}
