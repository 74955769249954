@mixin grid-to-list() {
  .article-wrapper {
    flex-direction: row;
  }

  .article-image {
    width: 100px;
    height: auto;
    min-height: 100px;
  }

  .article-container {
    padding: 0;
    padding-left: $middle-margin;
    width: 50%;
  }

  .field-teasertitle {
    padding: unset;
  }

  .items {
    margin: (0 - $photo-item-padding-v) (0 - $photo-item-padding-h);
  }

  .item {
    padding: 0 $photo-item-padding-h;
    margin: 0;
    a {
      @include border-basic(top, $border-gray);

      padding: $photo-item-padding-v 0;
    }
    &:first-child {
      a {
        border-width: 0;
      }
    }
  }

  .bg-alt &,
  &.bg-alt {
    .article-container {
      padding: ($small-margin + $extrasmall-margin);
    }

    .items {
      margin: (0 - $photo-item-padding-card-v) (0 - $photo-item-padding-card-h);
    }

    .item {
      padding: $photo-item-padding-card-v $photo-item-padding-card-h;
      a {
        border: none;
        padding: 0;
      }
    }
  }
}
