.journeys-article-listing {
  ~ .journeys-article-list.article-listing.component.page-list {
    margin: map-get($page-section-padding, sm) 0;
    padding: 0;

    @include media-breakpoint-up(md) {
      padding: 0 map-get($page-section-padding, xxs);
    }

    .component-content {
      max-width: unset;
      padding: 0;

      @include media-breakpoint-up(md) {
        margin: 0 $gutter-md;
        width: calc(100% - #{$gutter-md});
      }

      @include media-breakpoint-up(lg) {
        margin: 0 $gutter-lg;
        width: calc(100% - #{$gutter-lg});
      }

      @include media-breakpoint-up(xl) {
        margin: 0 $gutter-xl;
        width: calc(100% - #{$gutter-xl});
      }

      @include media-breakpoint-up(xxl) {
        margin: 0 $gutter-xxl;
        width: calc(100% - #{$gutter-xxl});
      }
    }

    ul.items {
      list-style: none;
      padding-left: 0;
      display: flex;
      flex-wrap: wrap;
      margin-right: -($mobile-screen-padding-h / 2);
      margin-left: -($mobile-screen-padding-h / 2);

      @include media-breakpoint-up(sm) {
        margin-left: 0;
        margin-right: 0;
      }

      @include media-breakpoint-up(md) {
        margin: 0 -5px 1rem -5px;
      }
    }

    .item {
      padding: 0;
      width: 100%;
      height: 330px;
      flex: 0 1 auto;
      position: relative;

      @include media-breakpoint-up(md) {
        height: 205px;
        margin-bottom: 10px;
        padding: 0 5px;
        &:not(:first-child) {
          width: 50%;
        }
        &:first-child {
          height: 255px;
        }
      }

      @include media-breakpoint-up(lg) {
        height: 400px;
        &:first-child {
          margin-bottom: 10px;
          height: 450px;
        }
      }

      a {
        overflow: hidden;
        display: block;

        .article-wrapper {
          transition: transform 0.7s;
        }

        @include hover-focus() {
          text-decoration: none !important;
          @include media-breakpoint-up(md) {
            .article-wrapper {
              transform: scale(1.05);
            }
          }
        }

        @include media-breakpoint-up(lg) {
          @include hover-focus() {
            .type-title-wrapper {
              height: 65%;
            }
          }
        }
      }

      a,
      .article-wrapper,
      .inner-wrapper,
      .copy-wrapper {
        height: 100%;
      }

      .photo-tint {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        position: absolute;
        top: 0;
        left: 0;
        @include linear-gradient(transparent, rgba(0, 0, 0, 0.65));

        background-clip: content-box;
        padding: 0;

        @include media-breakpoint-up(md) {
          padding: 0 5px;
        }
      }

      .copy-wrapper {
        position: relative;
        width: 100%;
        padding: 0 2.5rem 0 1rem;
        overflow: hidden;

        @include media-breakpoint-up(md) {
          padding: 0 2.2rem;
        }
        @include media-breakpoint-up(xl) {
          padding: 0 3rem;
        }

        & * {
          color: $white;
        }

        .type-title-wrapper {
          height: 100%;
          transition: height 0.7s;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
        }
      }

      .field-title {
        @include media-breakpoint-up(md) {
          margin-bottom: 1rem;
        }
        @include media-breakpoint-up(lg) {
          margin-bottom: 1.5rem;
        }
        @include media-breakpoint-up(xl) {
          margin-bottom: 2rem;
        }
      }

      // Specific styles for the first full-width article-item
      &:first-child {
        .photo-tint {
          position: absolute;
          height: 100%;
          top: 0;
          overflow: hidden;
        }
      }
    }

    ~ .list-pagination {
      margin-bottom: map-get($page-section-padding, sm);

      @include media-breakpoint-up(md) {
        margin-bottom: map-get($page-section-padding, md);
      }

      @include media-breakpoint-up(lg) {
        margin-bottom: map-get($page-section-padding, lg);
      }
    }

    // After the first listing page, make the first article image the same size as the other article images
    &.not-first-page {
      .item:first-child {
        @include media-breakpoint-up(md) {
          height: 205px;
          width: 50%;
        }

        @include media-breakpoint-up(lg) {
          height: 400px;
        }
      }
    }
  }
}
