// Styles specific to the hero banner belong here

.component.banner.hero-banner {
  &:first-child {
    padding-top: 0 !important;
  }

  .text-wrapper {
    @include media-breakpoint-up(md) {
      min-height: $banner-tall-min-height-md;
    }

    @include media-breakpoint-up(lg) {
      min-height: $banner-tall-min-height-lg;
    }
  }

  // Left align hero banner content for mobile regardless of any chosen modifier class
  @include media-breakpoint-down(sm) {
    .component-content {
      .banner-container {
        text-align: left;
      }

      .text-wrapper {
        align-items: flex-start;
      }
    }
  }
}
