/* Button styles copied from Bootstrap in a way such that they can be used by any components across our SXA solution */
@import 'mixins/buttons';
@import 'mixins/rich-text';
@import 'mixins/gridToList';
@import 'mixins/respond';

@mixin wrapper() {
  max-width: 960px;
  margin: 0 auto !important;
}
@mixin clearfix {
  &::after {
    content: '';
    display: table;
    clear: both;
  }
}
@mixin headings($from: 1, $to: 6) {
  @for $i from $from through $to {
    h#{$i} {
      @content;
    }
  }
}
@mixin font-size-pixel-fallback($sizeValue: 1.6) {
  font-size: ($sizeValue * 10) + px;
  font-size: $sizeValue + rem;
}
@mixin proxima-font($weight: semibold) {
  @if ($weight==semibold) {
    font-family: 'ProximaNova-Semibold', arial, helvetica, sans-serif;
  } @else if($weight==light) {
    font-family: 'ProximaNova-Light', arial, helvetica, sans-serif;
  }
}
@mixin opensans-font-stack() {
  font-family: 'Open Sans', Helvetica, Verdana, Tahoma, sans-serif;
}
@mixin loading-gif() {
  background-image: url(data:image/svg+xml;charset=utf-8;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzMiAzMiIgd2lkdGg9IjMyIiBoZWlnaHQ9IjMyIiBmaWxsPSJibGFjayI+DQogIDxwYXRoICBvcGFjaXR5PSIuMjUiIGQ9Ik0xNiAwIEExNiAxNiAwIDAgMCAxNiAzMiBBMTYgMTYgMCAwIDAgMTYgMCBNMTYgNCBBMTIgMTIgMCAwIDEgMTYgMjggQTEyIDEyIDAgMCAxIDE2IDQiLz4NCiAgPHBhdGggZmlsbD0nIzFhODBiNicgZD0iTTE2IDAgQTE2IDE2IDAgMCAxIDMyIDE2IEwyOCAxNiBBMTIgMTIgMCAwIDAgMTYgNHoiPg0KICAgIDxhbmltYXRlVHJhbnNmb3JtIGF0dHJpYnV0ZU5hbWU9InRyYW5zZm9ybSIgdHlwZT0icm90YXRlIiBmcm9tPSIwIDE2IDE2IiB0bz0iMzYwIDE2IDE2IiBkdXI9IjAuOHMiIHJlcGVhdENvdW50PSJpbmRlZmluaXRlIiAvPg0KICA8L3BhdGg+DQo8L3N2Zz4NCg==);
  background-position: center center;
  background-repeat: no-repeat;
}
@mixin border-basic($position: all, $border-color: $border-gray, $border-width: 1px) {
  @if ($position!=all) {
    border-#{$position}-width: $border-width;
    border-#{$position}-style: solid;
    border-#{$position}-color: $border-color;
  } @else {
    border-width: $border-width;
    border-style: solid;
    border-color: $border-color;
  }
}
@mixin fixed-bg($pos, $min-height: 240px) {
  background-position: $pos;
  background-attachment: fixed;
  min-height: $min-height;
}
@mixin linear-gradient($direction, $color-stops...) {
  // Direction has been omitted and happens to be a color-stop
  @if is-direction($direction) ==false {
    $color-stops: $direction, $color-stops;
    $direction: 180deg;
  }

  background: nth(nth($color-stops, 1), 1);
  background: -webkit-linear-gradient(legacy-direction($direction), $color-stops);
  background: linear-gradient($direction, $color-stops);
}

@mixin list-inline() {
  list-style: none;
  margin: 0;
  padding: 0;
  > li {
    display: inline-block;
  }
}

@mixin placeholder() {
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
  &::-webkit-input-placeholder {
    @content;
  }
}
