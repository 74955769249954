.html-block {
  .component-content {
    padding-left: 0;
    padding-right: 0;

    @include media-breakpoint-up(md) {
      padding-left: map-get($page-section-padding, xxs);
      padding-right: map-get($page-section-padding, xxs);
    }
  }

  h2 {
    text-align: center;
  }

  // Responsive tables
  .info-table {
    margin: $extralarge-margin 0 0;

    .table-responsive > table {
      thead {
        display: none;

        @include media-breakpoint-up(md) {
          display: table-header-group;
        }

        th {
          padding: 0 $table-cell-padding;
        }
      }

      tbody {
        @include media-breakpoint-up(md) {
          border-top: (2 * $table-border-width) solid $gray-200;
        }

        tr td {
          @include media-breakpoint-down(sm) {
            padding: $table-cell-padding-sm;
            float: left;
            width: 100%;
            border-left: 4px solid $gray-200;
            border-right: 4px solid $gray-200;

            &::before {
              content: attr(data-header-label) ':';
              display: inline-block;
              margin-right: 6px;
              font-weight: $font-weight-bold;
            }

            &:first-child {
              border-top: 4px solid $gray-200;
            }

            &:last-child {
              border-bottom: 4px solid $gray-200;
            }
          }

          @include media-breakpoint-up(md) {
            padding: $table-cell-padding;
            vertical-align: top;
            border-top: $table-border-width solid $gray-200;
          }
        }
      }
    }
  }

  &.is-inline .component-content {
    @include make-container-max-widths($container-max-widths-narrow);
  }
}
