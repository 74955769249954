.bubble-icons {
  max-width: 240px;
  margin: 0 auto;

  @include media-breakpoint-up(sm) {
    max-width: 360px;
  }

  @include media-breakpoint-up(lg) {
    max-width: 100%;
  }
}

.bubble-icons-list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  list-style-type: none;
  margin: 0;
  padding: 0;
  gap: map-get($spacers, 3);

  @include media-breakpoint-up(lg) {
    gap: map-get($spacers, 4);
  }
}

.bubble-icons-list-item {
  width: 100px;

  @include media-breakpoint-up(lg) {
    width: 100px;
  }
}

// Link

.bubble-icons-link {
  display: block;
  text-decoration: none !important;
}

.bubble-icons-link-icon {
  display: block;
  line-height: 70px;
  color: #fff;
  margin: 0 auto;
  border-radius: 100%;
  transition: transform 200ms ease;
  overflow: hidden;
  font-size: 40px;
  width: 70px;
  text-align: center;
  margin-bottom: map-get($spacers, 2);

  @include media-breakpoint-up(lg) {
    line-height: 100px;
    font-size: 50px;
    width: 100%;
    margin-bottom: map-get($spacers, 3);
  }

  .bubble-icons-link:hover & {
    transform: scale(1.1);
  }
}

.bubble-icons-link-text {
  margin: 0;
  text-align: center;
  line-height: 1.2;
}
