$accordion-padding-h: 0;

.accordion {
  .items {
    overflow: hidden;
    padding: 0;
    margin: 0;
  }

  .item {
    @include border-basic(top);
    &:last-child {
      @include border-basic(bottom);
    }
  }

  .toggle-header {
    all: unset;
    cursor: pointer;
    background-color: $accordion-header-bg;
    appearance: none;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: $spacer $accordion-padding-h;

    &:hover,
    &:focus {
      color: $active-blue;
      background-color: $accordion-header-bg-active;
    }

    .field-heading {
      position: relative;
      font-weight: $font-weight-bold;
      margin-top: 0;
      padding-right: 16px;
    }

    .toggle-icon {
      width: 20px;
      color: $active-blue;
    }
  }

  // active styles
  .active {
    .toggle-header .component .field-heading::after {
      content: fa-content($fa-var-chevron-up);
    }
  }

  // content pane styles
  .toggle-content {
    padding: 0 map-get($spacers, 5) 1.5em $accordion-padding-h;
    background-color: $accordion-toggled-bg;
  }
}
@import 'styles/accordion/accordion-horizontal';
