// Styles specific to the promotional banner belong here

.component.banner.promotional-banner {
  // lighten the font weight of the title
  .field-bannertitle {
    margin-bottom: 0.2rem;
  }

  // set some responsive heights for the banner according to the configured class
  .text-wrapper,
  &.photo-bg {
    min-height: 100px;
  }

  .banner-medium {
    .text-wrapper,
    &.photo-bg {
      min-height: $banner-min-height-sm;
      @include media-breakpoint-up(md) {
        min-height: $banner-min-height-md;
      }
      @include media-breakpoint-up(lg) {
        min-height: $banner-min-height-lg;
      }
    }
  }
  .banner-tall {
    .text-wrapper,
    &.photo-bg {
      min-height: $banner-tall-min-height-sm;
      @include media-breakpoint-up(md) {
        min-height: $banner-tall-min-height-md;
      }
      @include media-breakpoint-up(lg) {
        min-height: $banner-tall-min-height-lg;
      }
    }
  }

  .banner-titles {
    .field-bannerdescription {
      text-shadow: 0 0 100px rgba(red($midnight-blue), green($midnight-blue), blue($midnight-blue), 0.2),
        0 0 12px rgba(red($midnight-blue), green($midnight-blue), blue($midnight-blue), 0.4),
        0 1px 2px rgba(red($midnight-blue), green($midnight-blue), blue($midnight-blue), 0.3);
    }
  }

  // If content is set to right alignment on desktop, centre-align the content for mobile, regardless.
  @include media-breakpoint-down(sm) {
    .component-content {
      .content-top-right,
      .content-middle-right,
      .content-bottom-right {
        .banner-container {
          text-align: center;
        }
        .text-wrapper {
          align-items: center;
        }
      }
    }
  }
}
