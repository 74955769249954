// Styles specific to the hero banner belong here

.component.banner.hero-banner {
  .text-wrapper {
    // set some responsive min heights for the hero banner
    @include media-breakpoint-up(md) {
      min-height: $banner-tall-min-height-md;
    }

    @include media-breakpoint-up(lg) {
      min-height: $banner-tall-min-height-lg;
    }
  }

  // Left align hero banner content for mobile regardless of any chosen modifier class
  @include media-breakpoint-down(sm) {
    .component-content {
      .banner-container {
        text-align: left;
      }

      .text-wrapper {
        align-items: flex-start;
      }
    }
  }
}

.component.banner.hero-with-video {
  .component-content {
    max-height: 860px;
    overflow: hidden;
  }

  .page-banner.hero-video {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
  }

  .hero-video {
    min-height: unset;
  } // needed to unset the min-height at all breakpoints

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .photo-tint {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
